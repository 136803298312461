import React from "react";
import Dialog from "@mui/material/Dialog";
import Paper, { PaperProps } from "@mui/material/Paper";

const PaperComponent = (paperStyle: React.CSSProperties) => {
	return (props: PaperProps) => (
		<Paper elevation={0} {...props} style={paperStyle} />
	);
};

interface Props {
	onClose: () => void;
	open: boolean;
	children?: React.ReactNode;
	size?: "sm" | "md" | "lg";
	style?: React.CSSProperties;
	paperStyle?: React.CSSProperties;
}

const MuiDialog: React.FC<Props> = ({
	onClose,
	open,
	children,
	size,
	style,
	paperStyle,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth={size ? size : "sm"}
			PaperComponent={PaperComponent({
				outline: "10px solid rgba(255,255,255,0.25)",
				...paperStyle,
			})}
			style={style}
		>
			{children}
		</Dialog>
	);
};

export default MuiDialog;
