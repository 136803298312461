import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Text } from "styled/muiComponents";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "@mui/material/Button";

export const LoginWrapper = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	display: "flex",
	width: "100%",
	height: "100dvh",
	padding: theme.spacing(3),
	overflow: "auto",
}));

export const LoginContainer = styled(Grid)(({ theme }) => ({
	backgroundColor: "#f3f3f3",
	display: "flex",
	width: "100%",
	padding: theme.spacing(3),
	borderRadius: theme.spacing(1),
	outline: "10px solid rgba(255,255,255,0.5)",
	margin: 0,
}));

export const ClassRoomContainer = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	height: "100%",
	fontFamily: theme.typography.body1.fontFamily,
	backgroundSize: "cover",
}));
export const LoadingBubble = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.white.light,
	padding: theme.spacing(3),
	borderRadius: "10px",
	marginTop: theme.spacing(2),
	boxShadow: "10px 5px 30px rgba(0,0,0,0.20)",
}));
export const MainContent = styled(Grid)(({ theme }) => ({
	flexGrow: "1",
	width: "80%",
	fontFamily: theme.typography.body1.fontFamily,
}));
export const SideBar = styled(Grid)(({ theme }) => ({
	width: "20%",
	//borderLeft: `1px solid ${theme.palette.white.light}`,
	fontFamily: theme.typography.body1.fontFamily,
}));
export const ToolButtonContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	//position: "absolute",
	padding: "5px",
	//top: 0,
	//left: 0,
	minWidth: "400px",
	height: "60px",
	zIndex: 200,
}));
export const SearchField = styled(Text)(({ theme }) => ({
	height: "40px",
	minWidth: "10px !important",
	marginLeft: "24px",
	width: "calc(100% - 24px)",
}));
export const CountdownContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	//position: "absolute",
	//padding: "15px",
	//top: 0,
	//right: 0,
}));
export const SlidesWrapper = styled(Grid)(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	height: "100%",
	gap: "10px",
	position: "fixed",
	width: "80%",
	maxHeight: "calc(100vh - 60px)",
	overflow: "hidden",
	left: "0px",
	bottom: "0px",
	margin: "0px !important",
	paddingBottom: "15px",
	paddingRight: theme.spacing(0),
	backgroundColor: theme.palette.primary.main,
	boxSizing: "border-box",
	zIndex: 100,
}));

export const Scrollbar = styled(PerfectScrollbar)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	gap: "10px",
	width: "100%",
	maxHeight: "100vh",
	overflow: "auto",
	paddingRight: "15px",
	paddingLeft: "14px",
	borderRadius: "5px",
}));

export const Stream = styled(Grid)(({ theme }) => ({
	aspectRatio: "4 / 6",
	width: "100%",
	borderBottom: `1px solid ${theme.palette.white.light}`,
	borderTop: `1px solid ${theme.palette.white.light}`,
}));
export const NavBar = styled(Grid)(({ theme }) => ({
	position: "relative",
	height: "60px",
	color: "#ffffff",
	paddingRight: "15px",
}));
export const Stages = styled(Grid)(({ theme }) => ({
	flexGrow: 1,
	height: "calc(100% - 120px)",
}));
export const SlideNavButton = styled(Button)(({ theme }) => ({
	width: "30px",
	height: "30px",
	padding: "0px !important",
	transition: "all 0.1s ease",
	minWidth: "10px !important",
	borderRadius: "15px",
}));
export const MediaToolBar = styled(Grid)(({ theme }) => ({
	display: "flex",
	padding: theme.spacing(0.75),
	transition: "all 0.1s ease",
	borderRadius: "50px",
	border: `1px solid ${theme.palette.white.light}`,
	gap: "5px",
}));
export const AchivementContainer = styled(Grid)(({ theme }) => ({
	position: "absolute",
	height: "100vh",
	width: "100%",
	zIndex: "1000",
	backgroundColor: "rgba(0,0,0,0.5)",
}));
