import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSessionStatus } from "store/reducers/session";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "rtk/api";
import { Button } from "styled/muiComponents";
import { useTheme } from "@mui/material/styles";
import { setNavbarState } from "store/reducers/app/reducer";
import Divider from "@mui/material/Divider";
import { Fa, FaButton } from "../../styled/muiComponents";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.main,
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
}));
type Page = {
	name: string;
	icon: React.ReactNode;
};
const pages: Page[] = [
	{
		name: "Dashboard",
		icon: <Fa icon={["fat", "gauge-high"]} />,
	},
	{
		name: "Timetable",
		icon: <Fa icon={["fat", "calendar-star"]} />,
	},
	{
		name: "Students",
		icon: <Fa icon={["fat", "user-graduate"]} />,
	},
	{ name: "Courses", icon: <Fa icon={["fat", "school-flag"]} /> },
	{ name: "Material", icon: <Fa icon={["fat", "rectangle-history"]} /> },
];

const NavBarStudent = () => {
	const navigate = useNavigate();
	const session = useAppSelector((state) => state.session);
	const { t } = useTranslation();
	const sessionStatus = useAppSelector(selectSessionStatus);
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);

	const [logout] = useLogoutMutation();
	const navbarState = useAppSelector((state) => state.app.navbarState);
	const dispatch = useAppDispatch();
	const theme = useTheme();

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleLogout = async () => {
		await logout();
		dispatch(setNavbarState("closed"));
	};

	const goto = (route: string) => {
		console.log("going to ", route);
		navigate(route);
		handleCloseUserMenu();
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const GuruToolTip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.secondary.main,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.primary.main,
			fontSize: "1rem",
		},
	}));

	if (navbarState === "closed") {
		return null;
	}

	return (
		<StyledAppBar position="static">
			<Toolbar
				disableGutters
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "space-between",
					flexDirection: "row",
					height: "90px",
					width: "100%",
					maxWidth: "1200px",
					padding: "8px 0px",
					boxSizing: "border-box",
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						display: { xs: "flex", md: "none" },
					}}
				>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: "block", md: "none" },
						}}
					>
						{pages.map((page) => (
							<MenuItem
								key={page.name}
								onClick={() => goto(page.name)}
							>
								<Typography textAlign="center">
									{page.name}
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Box>
				<Box
					style={{
						cursor: "pointer",
					}}
				>
					<img
						src="/assets/class-guru.svg"
						alt="Classuru"
						onClick={() => goto("dashboard")}
						style={{
							height: "55px",
						}}
						key="logo"
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						display: { xs: "none", md: "flex" },
						direction: "column",
					}}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "space-between",
						flexDirection: "row",
					}}
				>
					{pages.map((page) => (
						<GuruToolTip title={page.name} arrow placement="bottom">
							<FaButton
								key={page.name}
								onClick={() => goto(page.name.toLowerCase())}
								style={{
									width: "50px",
									height: "50px",
									color: "#ffffff",
								}}
							>
								{page.icon}
							</FaButton>
						</GuruToolTip>
					))}
				</Box>

				{sessionStatus === "loggedin" && (
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<IconButton
								onClick={handleOpenUserMenu}
								sx={{ p: 0 }}
							>
								<Avatar
									alt="Remy Sharp"
									src="/static/images/avatar/2.jpg"
								/>
							</IconButton>
						</Tooltip>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							<MenuItem onClick={() => goto("profile")}>
								<Typography textAlign="center">
									{t("Profile")}
								</Typography>
							</MenuItem>
							<MenuItem onClick={() => goto("dashboard")}>
								<Typography textAlign="center">
									{t("Settings")}
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleLogout}>
								<Typography textAlign="center">
									{t("Logout")}
								</Typography>
							</MenuItem>
						</Menu>
					</Box>
				)}
				{sessionStatus === "loggedout" && (
					<Button
						onClick={() => goto("/login")}
						color="success"
						variant="outlined"
					>
						Login
					</Button>
				)}
			</Toolbar>
		</StyledAppBar>
	);
};
export default NavBarStudent;
