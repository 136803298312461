import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum UserRole {
  Admin,
  Manager,
  Teacher,
  Student,
  Designer
}

export interface User {
  id: number,
  email: string,
  firstName: string | null,
  lastName: string | null,
  username: string,
  role: UserRole,
  createdAt: Date
}

export interface Realm {
  id: number,
  firstName: string | null,
  lastName: string | null,
  companyName: string | null,
  streetName: string | null,
  streetNumber: string | null
  city: string | null,
  postalCode: string | null,
  country: string | null,
  website: string | null,
  gender: string | null,
  vatIdentifier: string | null
}

export type SessionState = {
  user: User | null,
  realm: Realm | null,
  status: 'uninitialized' | 'loggedin' | 'loggedout'
}

const initialState: SessionState = {
  status: 'uninitialized',
  user: null,
  realm: null
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionState>) => {
      console.log("received action: ", action)
      state.user = action.payload.user;
      state.realm = action.payload.realm;
      state.status = action.payload.status;
    }
  },
  extraReducers: () => {
  },
});


export const selectSession = (state: RootState) => state.session;
export const selectUser = (state: RootState) => state.session.user;
export const selectRealm = (state: RootState) => state.session.realm;
export const selectSessionStatus = (state: RootState) => state.session.status;

export const { setSession } = sessionSlice.actions;
export default sessionSlice.reducer;
