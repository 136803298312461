import { JitsiMeeting, JaaSMeeting } from "@jitsi/react-sdk";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
//import { IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { Button, Fade, TextField } from "@mui/material";
import { useRef, forwardRef, useEffect, useState } from "react";
import { useJwtTokenQuery } from "rtk/api";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import ToolButton from "../../routes/app/Class/components/ToolButton";
import { Fa } from "../../styled/muiComponents";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";
import { View, selectClass, setStudentTool } from "store/reducers/class/class";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Typography from "@mui/material/Typography";

const StyledScreenShareContainerGrid = styled(Grid)(({ theme }) => ({
	display: "flex",
	overflow: "hidden",
	width: "100%",
	height: "100%",
	paddingLeft: "15px",
	paddingRight: "15px",
}));
const CamToolsContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	padding: "5px",
	bottom: 0,
	left: 0,
	width: "´100px",
	height: "50px",
	zIndex: 100,
	opacity: 0.25,
	transition: "all 0.5s ease",
}));

type Props = {
	type: any;
	teacher: boolean;
};

const ScreenShare = forwardRef(({ type, teacher }: Props, ref) => {
	const { t } = useTranslation();
	const API = useRef<IJitsiMeetExternalApi>(null);
	const { data, error, isLoading } = useJwtTokenQuery();
	const { roomId } = useAppSelector(selectClass);
	const [ready, setReady] = useState({ joined: false, screenSharing: false });

	const screenShareListener = (value: any) => {
		console.log("someone is screensharing");
		// const iframe = API.current?.getIFrame()
		// if(iframe) iframe.style.display="flex"
		//API.current?.executeCommand('pinParticipant', value[0])
		setReady({ joined: true, screenSharing: true });
		API.current?.executeCommand("toggleFilmStrip");
	};

	const startActivity = () => {
		if (teacher && type === View.ScreenSharing) {
			API.current?.executeCommand("toggleShareScreen");
		}
	};

	if (isLoading || !data) return <div>loading...</div>;
	if (!roomId) return <div>no room id</div>;

	return (
		<Grid
			item
			container
			direction="column"
			justifyItems="center"
			justifyContent="center"
			style={{ height: "100%", padding: 0, margin: 0 }}
		>
			{teacher && (
				<Fade
					in={!ready.joined && !ready.screenSharing}
					unmountOnExit={true}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "80%",
							alignItems: "center",
							position: "absolute",
						}}
					>
						<img
							src="/assets/class-guru.svg"
							alt="Classuru"
							style={{ height: "100px" }}
						/>
						<Typography
							textAlign="center"
							variant="h1"
							color="#ffffff"
							mt={2}
							mb={4}
						>
							{t("Share your screen, share your knowlage.")}
						</Typography>
						<Button
							variant="contained"
							disableElevation
							color="secondary"
							onClick={startActivity}
						>
							{t("Start sharing")}
						</Button>
					</div>
				</Fade>
			)}
			<Fade in={ready.joined && ready.screenSharing}>
				<StyledScreenShareContainerGrid
					container
					item
					direction="column"
					justifyItems="center"
					justifyContent="center"
					id="container"
				>
					<div
						style={{
							borderRadius: "5px",
							height: "100%",
							overflow: "hidden",
						}}
					>
						<JaaSMeeting
							appId={
								"vpaas-magic-cookie-c4914d37f4ae4551942881ba2a88d601"
							} // TODO ENV
							jwt={data.token}
							roomName={`${roomId}-test`}
							interfaceConfigOverwrite={{
								DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
								DISPLAY_WELCOME_FOOTER: false,
								MOBILE_APP_PROMO: false,
								SETTINGS_SECTIONS: [],
								VIDEO_QUALITY_LABEL_DISABLED: true,
								SHOW_CHROME_EXTENSION_BANNER: false,
								AUTO_PIN_LATEST_SCREEN_SHARE: true, //apperantly still in beta | might break
							}}
							configOverwrite={{
								startWithAudioMuted: true,
								disableModeratorIndicator: true,
								startWithVideoMuted: true,
								//startScreenSharing: true,
								enableEmailInStats: false,
								prejoinPageEnabled: false,
								toolbarButtons: [],
								hideConferenceTimer: true,
								hideConferenceSubject: true,
								hideDisplayName: true,
								filmStrip: {
									disableStageFilmstrip: true,
								},

								//disble names

								//disableResizable: true,
								//disableStageFilmstrip: true,
								// participantsPane: {
								// hideModeratorSettingsTab: true,
								// hideMoreActionsButton: true,
								// hideMuteAllButton: true,
								// }
							}}
							getIFrameRef={(camRef: any) => {
								//camRef.style.aspectRatio = "4/3";
								camRef.style.width = "100%";
								camRef.style.height = "100%";
								// camRef.style.display = "none"
							}}
							onApiReady={(externalApi: any) => {
								//@ts-ignore TODO fix whatever it says
								API.current = externalApi;
								//externalApi.addListener('participantJoined', startActivity)
								externalApi.addListener(
									"contentSharingParticipantsChanged",
									screenShareListener
								);
								externalApi.addListener(
									"participantJoined",
									() => {
										externalApi.executeCommand(
											"toggleFilmStrip"
										); //! not working properly
									}
								);
							}}
						/>
					</div>
				</StyledScreenShareContainerGrid>
			</Fade>
		</Grid>
	);
});

export default ScreenShare;
