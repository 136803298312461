import React from "react";
import { useAppSelector } from "store/hooks";
import {
	selectLayer,
	RawLayer as IRawLayer,
} from "store/reducers/class/layers";
import { RawItem as IRawItem } from "store/reducers/class/items";
import { Layer as KonvaLayer } from "react-konva";
import Item, { RawItem } from "./Item";

type Props = {
	layerId: string;
	children?: (itemIds: string[]) => React.ReactNode;
	scale?: { width: number; height: number };
};

const Layer = ({ layerId, children, scale }: Props) => {
	const layer = useAppSelector(selectLayer(layerId));

	if (!children) {
		return (
			<KonvaLayer>
				{layer.itemIds.map((itemId) => (
					<Item key={itemId} itemId={itemId} scale={scale} />
				))}
			</KonvaLayer>
		);
	} else {
		return <KonvaLayer>{children(layer.itemIds)}</KonvaLayer>;
	}
};

type RawProps = {
	layer: IRawLayer;
	children?: (items: IRawItem[]) => React.ReactNode;
	scale?: { width: number; height: number };
};

export const RawLayer = ({ layer, children, scale }: RawProps) => {
	if (!children) {
		return (
			<KonvaLayer>
				{layer.items.map((item) => (
					<RawItem key={item.id} item={item} scale={scale} />
				))}
			</KonvaLayer>
		);
	} else {
		return <KonvaLayer>{children(layer.items)}</KonvaLayer>;
	}
};

export default Layer;
