import { Button, ButtonGroup, Fade, Grid, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Fa } from "styled/muiComponents";

const StarsSpreadContainer = styled(Grid)(({ theme }) => ({}));
const StarsCountContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	//position: "absolute",
	//padding: "15px",
	//top: 0,
	//right: "130px",
}));
const StarsContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	padding: "12px",
	top: "60px",
	right: "130px",
	zIndex: 150,
	backgroundColor: theme.palette.primary.main,
	borderRadius: "30px",
	boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.3)",
	outline: "1px solid rgb(255 255 255 / 15%)",
}));
const StarsCountButton = styled(Button)(({ theme }) => ({
	height: "30px",
	transition: "all 0.1s ease",
	minWidth: "10px !important",
	borderRadius: "15px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const ContainedStars = ({
	value,
	onChange,
	disabled,
}: Omit<Props, "variant">) => {
	const [showStars, setShowStars] = useState(false);
	const showStarsButtonRef = useRef<HTMLButtonElement | null>(null);

	const starsContainerRef = useRef<HTMLDivElement | null>(null);
	const handleClickOutside = (event: MouseEvent) => {
		const targetElement = event.target as Node;
		if (
			!starsContainerRef.current?.contains(targetElement) &&
			!targetElement.isEqualNode(showStarsButtonRef.current) &&
			!showStarsButtonRef.current?.contains(targetElement)
		) {
			setShowStars(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handleEscKey);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handleEscKey);
		};
	}, []);

	const handleEscKey = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			setShowStars(false);
		}
	};

	return (
		<>
			<StarsCountContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
					}}
				>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						style={{
							border: "1px solid rgba(255, 255, 255, 0.15)",
							borderRadius: "15px",
							display: "flex",
							gap: "1px",
						}}
					>
						<StarsCountButton
							variant="contained"
							size="small"
							disableElevation
							style={{
								borderRight:
									"1px solid rgba(255, 255, 255, 0.15)",
							}}
							disabled={value === 0}
							onClick={() => onChange(value - 1)}
						>
							<Fa icon={["far", "minus"]} fixedWidth />
						</StarsCountButton>
						<StarsCountButton
							variant="contained"
							size="small"
							disableElevation
							style={{
								borderRight:
									"1px solid rgba(255, 255, 255, 0.15)",
								backgroundColor: showStars
									? "rgba(255, 255, 255, 0.9)"
									: "",
								color: showStars
									? "black"
									: "rgba(255, 255, 255, 0.9)",
							}}
							onClick={() => setShowStars(!showStars)}
							ref={showStarsButtonRef}
						>
							<Fa
								icon={["fas", "star"]}
								fixedWidth
								style={{ marginRight: "10px", color: "orange" }}
							/>{" "}
							{value} / 10
						</StarsCountButton>
						<StarsCountButton
							variant="contained"
							size="small"
							disableElevation
							onClick={() => onChange(value + 1)}
						>
							<Fa icon={["far", "plus"]} fixedWidth />
						</StarsCountButton>
					</ButtonGroup>
				</div>
			</StarsCountContainer>
			<Fade in={showStars}>
				<StarsContainer ref={starsContainerRef}>
					<SpreadStars
						value={value}
						onChange={onChange}
						disabled={disabled}
					/>
				</StarsContainer>
			</Fade>
		</>
	);
};

const SpreadStars = ({ value, onChange, disabled }: Omit<Props, "variant">) => {
	return (
		<StarsSpreadContainer
			container
			justifyContent="center"
			alignItems="center"
		>
			{Array.from(Array(10)).map((_, index) => (
				<Fa
					key={index}
					icon={value > index ? ["fas", "star"] : ["fal", "star"]}
					fixedWidth
					style={{
						color: "orange",
						cursor: disabled ? "default" : "pointer",
					}}
					size="lg"
					onClick={!disabled ? () => onChange(index + 1) : undefined}
				/>
			))}
		</StarsSpreadContainer>
	);
};

type Props = {
	variant?: "contained" | "spread";
	value: number;
	onChange: (value: number) => void;
	disabled?: boolean;
};

const Stars = ({ variant = "spread", ...props }: Props) => {
	switch (variant) {
		case "contained":
			return <ContainedStars {...props} />;
		case "spread":
			return <SpreadStars {...props} />;
		default:
			return null;
	}
};

export default Stars;
