import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { Button } from "@mui/material";
import { Fa, FaButton } from "../../../styled/muiComponents";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useGetStudentQuery } from "rtk/api";
import { Lesson } from "rtk/types";
function stringToColor(string: any) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name: any) {
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: 56,
			height: 56,
		},
		children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
	};
}
const StyledCard = styled(Card)(({ theme }) => ({
	padding: `${theme.spacing(3)}`,
	marginBottom: `${theme.spacing(2)}`,
	borderRadius: `${theme.spacing(2)}`,
	width: "100%",
}));
const GuruToolTip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.secondary.main,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.main,
		fontSize: "1rem",
	},
}));
const TimeLineCard = ({ lesson }: { lesson: Lesson }) => {
	// console.log(lesson);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { data, error, isLoading, isSuccess, isError } = useGetStudentQuery(
		lesson.studentId?.toString() as string
	);

	const startTime = (dateTime: string) => {
		const date = new Date(dateTime);

		const hours = date.getHours();
		const minutes = date.getMinutes();

		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 || 12;

		const formattedTime = `${formattedHours}:${
			minutes < 10 ? "0" : ""
		}${minutes} ${ampm}`;
		return formattedTime;
	};

	return (
		<StyledCard elevation={0} variant="outlined">
			{/*<Lottie
									animationData={eventAnimation}
									loop={true}
									style={{
										maxWidth: "80px",
										display: "flex",
										alignItems: "center",
										margin: "0 auto",
									}}
								/>*/}
			<Grid container direction="row" alignItems="center" spacing={2}>
				<Grid item>
					{/* <Avatar {...stringAvatar("Johnt Dodds")} /> */}
					{data ? (
						<Avatar
							{...stringAvatar(
								`${data.firstName} ${data?.lastName}`
							)}
						/>
					) : (
						<Avatar {...stringAvatar("N A")} />
					)}
				</Grid>
				<Grid item flexGrow={1}>
					<Typography variant="body2" style={{ fontSize: "1.5em" }}>
						<strong>
							{/* 3:00 pm - {" "}*/}
							{startTime(lesson.startAt)} -{" "}
							{data
								? `${data.firstName} ${data.lastName}`
								: `${t("Not yet accepted/selected")}`}
						</strong>
					</Typography>
					<Stack direction="row" spacing={1}>
						<Typography variant="h4" style={{ fontSize: "1.15em" }}>
							Level 0 - Chapter 4 - Class 12
						</Typography>
					</Stack>
				</Grid>
				<Grid item>
					<GuruToolTip title="Cancel" arrow placement="bottom">
						<FaButton
							style={{
								width: "50px",
								height: "50px",
							}}
						>
							<Fa icon={["fal", "xmark"]} />
						</FaButton>
					</GuruToolTip>
					<GuruToolTip title="Postpone" arrow placement="bottom">
						<FaButton
							style={{
								width: "50px",
								height: "50px",
							}}
						>
							<Fa icon={["fal", "shuffle"]} />
						</FaButton>
					</GuruToolTip>
					<GuruToolTip title="Join" arrow placement="bottom">
						<FaButton
							style={{
								width: "50px",
								height: "50px",
							}}
						>
							<Fa icon={["fal", "right-to-bracket"]} />
						</FaButton>
					</GuruToolTip>
				</Grid>
			</Grid>
		</StyledCard>
	);
};

export default TimeLineCard;
