import React from "react";
import { styled } from "@mui/material/styles";
import MuiDialogActions from "@mui/material/DialogActions";

const StyledDialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: `${theme.spacing(3)}px !important`,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
}));

const DialogActions = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <StyledDialogActions
      //disableTypography
      {...other}
      style={{ padding: "16px 24px" }}
    >
      {" "}
      {/* padding is a fix. If you are reading this, check if the bug has already been fixed: https://github.com/mui/material-ui/issues/27851 */}
      {children}
    </StyledDialogActions>
  );
};

export default DialogActions;
