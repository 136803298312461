import { Image as KonvaImage } from "react-konva";
import useImage from "use-image";
import { Image as IImage, setLoadingState } from "store/reducers/class/items";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";

export type ImageProps = Omit<IImage, "type">;

const useImageDataUrl = (imageUrl: string) => {
	const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
	const [loadingState, setLoadingState] = useState<
		"loading" | "loaded" | "failed"
	>("loading");

	useEffect(() => {
		const loadImage = async () => {
			try {
				setLoadingState("loading");
				const response = await fetch(imageUrl);
				const blob = await response.blob();
				const reader = new FileReader();

				reader.onloadend = () => {
					const base64data = reader.result;
					setImageDataUrl(base64data as string);
					setLoadingState("loaded");
				};

				reader.readAsDataURL(blob);
			} catch (error) {
				setLoadingState("failed");
			}
		};

		loadImage();
	}, [imageUrl]);

	return [imageDataUrl, loadingState];
};

const useImageLoader = (
	imageUrl: string
): [HTMLImageElement | undefined, "loading" | "failed" | "loaded"] => {
	const [imageDataUrl, imageDataUrlLoadingState] = useImageDataUrl(imageUrl);
	const [image, imageLoadingState] = useImage(imageDataUrl as string);

	let loadingState: "loading" | "failed" | "loaded";
	if (
		imageDataUrlLoadingState === "loading" ||
		imageLoadingState === "loading"
	) {
		loadingState = "loading";
	} else if (
		imageDataUrlLoadingState === "failed" ||
		imageLoadingState === "failed"
	) {
		loadingState = "failed";
	} else if (
		imageDataUrlLoadingState === "loaded" &&
		imageLoadingState === "loaded"
	) {
		loadingState = "loaded";
	} else {
		throw new Error("Invalid loading state");
	}

	return [image, loadingState];
};

const Image = ({
	src,
	scale,
	...props
}: ImageProps & { scale?: { width: number; height: number } }) => {
	const [image, loadState] = useImageLoader(src);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			setLoadingState({
				id: props.id,
				loadState,
			})
		);
	}, [loadState]);

	return (
		<KonvaImage
			{...props}
			image={image}
			width={props.width * (scale?.width || 1)}
			height={props.height * (scale?.height || 1)}
		/>
	);
};

export const RawImage = ({
	src,
	scale,
	...props
}: ImageProps & { scale?: { width: number; height: number } }) => {
	const [image, loadState] = useImageLoader(src);
	console.warn("loadtsate not implemented");
	return (
		<KonvaImage
			{...props}
			image={image}
			width={props.width * (scale?.width || 1)}
			height={props.height * (scale?.height || 1)}
		/>
	);
};

export default Image;
