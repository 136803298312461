import { Rect as KonvaRect } from "react-konva";
import { Rect as IRect } from "store/reducers/class/items";

export type RectProps = Omit<IRect, "type" | "id"> & {
	scale?: { width: number; height: number };
};

const Rect = (props: RectProps) => {
	const { scale, width, height, x, y, ...rest } = props;
	return (
		<KonvaRect
			{...rest}
			x={x * (scale?.width || 1)}
			y={y * (scale?.height || 1)}
			width={width * (scale?.width || 1)}
			height={height * (scale?.height || 1)}
		/>
	);
};

export default Rect;
