import Hashids from "hashids";

export function encode(value: string): string {
	const te = new TextEncoder();
	const value_as_u8 = Array.from(te.encode(value));
	const hashids = new Hashids(process.env.REACT_APP_HASHIDS_SALT as string);
	return hashids.encode(value_as_u8);
}

export function decode(value: string): string {
	const hashids = new Hashids(process.env.REACT_APP_HASHIDS_SALT as string);
	const decoded = hashids.decode(value);
	const uint8ArrayDecoded = Uint8Array.from(decoded as number[]);
	const td = new TextDecoder();
	return td.decode(uint8ArrayDecoded);
}
