import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { init } from './class';
import { create as createStage, createWhiteboard } from './stages';

export type RawItem = Omit<Image, "loadingState"> | Rect | Line | Ping;

export enum ItemType {
  Image = 'image',
  Line = 'line',
  Rect = 'rect',
  Ping = 'ping'
}

/* TODO USE KONVA TYPE, no clue how though */
type LineJoin = 'round' | 'bevel' | 'miter';
type LineCap = 'butt' | 'round' | 'square';
type GlobalCompositeOperationType = '' | 'source-over' | 'source-in' | 'source-out' | 'source-atop' | 'destination-over' | 'destination-in' | 'destination-out' | 'destination-atop' | 'lighter' | 'copy' | 'xor' | 'multiply' | 'screen' | 'overlay' | 'darken' | 'lighten' | 'color-dodge' | 'color-burn' | 'hard-light' | 'soft-light' | 'difference' | 'exclusion' | 'hue' | 'saturation' | 'color' | 'luminosity';
export interface Loadable { loadingState: 'loading' | 'loaded' | 'failed'}


type Dimension = { width: number, height: number}
type Position = { x: number, y: number}
type BaseItem = { id: string, type: ItemType }
export type Image = BaseItem & { type: ItemType.Image } & Position & Dimension & { src: string } & Loadable
export type Rect = BaseItem & { type: ItemType.Rect } & Position & Dimension & { fill: string }
export type Line = BaseItem & { type: ItemType.Line } & { points: number[], stroke: string, strokeWidth: number, tension: number, lineCap?: LineCap, lineJoin?: LineJoin, globalCompositeOperation?: GlobalCompositeOperationType }
export type Ping = BaseItem & { type: ItemType.Ping } & Position & { fill: string }

export type Item = Image | Rect | Line | Ping


type ItemState = {
  itemById: {[key: string]: Item}
};

const initialState: ItemState = {
  itemById: {}
}

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    create: (state, { payload }: PayloadAction<{ stageId: string, layerId: string, item: Item}>) => {
      const { item } = payload;
      state.itemById[item.id] = item;
      if(item.type === ItemType.Image) {
        (state.itemById[item.id] as Image).loadingState = 'loading';
      }
      return state;
    },
    addPoints: (state, action: PayloadAction<{ id: string, point: number[] }>) => {
      const { id, point } = action.payload;
      if(state.itemById[id].type !== ItemType.Line) {
        throw new Error("AddPoints can only be called on a Line");
      }
      (state.itemById[id] as Line).points = (state.itemById[id] as Line).points.concat(point);

      return state;
    },
    remove: (state,  { payload }: PayloadAction<string | string[]>) => {
      let ids: string[];
      if(typeof payload === "string") {
        ids = [payload]
      } else {
        ids = payload
      }
      ids.forEach(id => {
        delete state.itemById[id];
      })
    },
    setLoadingState: (state, { payload }: PayloadAction<{ id: string, loadState: Loadable['loadingState'] }>) => {
      const { id, loadState } = payload;
      if(!("loadingState" in state.itemById[id])) {
        throw new Error("setLoadingState can only be called on loadable items");
      }
      (state.itemById[id] as Image).loadingState = loadState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(init, ( state, { payload }) => {
        state.itemById = payload.stages.map(stage => stage.layers).flat().map(layer => layer.items).flat().reduce((res, cur) => ({...res, [cur.id]: cur.type === ItemType.Image ? {...cur, loadingState: "loading"} : cur }), {})
      })
      .addCase(createStage, (state, { payload }) => {
        payload.layers.forEach(layer => {
          layer.items.forEach(item => {
            state.itemById[item.id] = item as Item;
            if(item.type === ItemType.Image) {
              (state.itemById[item.id] as Image).loadingState = 'loading';
            }
          })
        })
      })
      .addCase(createWhiteboard, (state, { payload }) => {
        payload.layers.forEach(layer => {
          layer.items.forEach(item => {
            state.itemById[item.id] = item as Item;
            if(item.type === ItemType.Image) {
              (state.itemById[item.id] as Image).loadingState = 'loading';
            }
          })
        })
      })

  },
});

export const selectItem = (id: string) => ({ items }: RootState) => items.itemById[id];

export const { create, addPoints, remove, setLoadingState } = itemSlice.actions;

export default itemSlice.reducer;
