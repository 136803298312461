import { Navigate, Outlet, useSearchParams } from "react-router-dom";

import { selectSessionStatus } from "../store/reducers/session";
import { useAppSelector } from "../store/hooks";

const PublicRoute = () => {
	const status = useAppSelector(selectSessionStatus);
	const [searchParams] = useSearchParams();

	if (status === "loggedin") {
		if (searchParams.get("ot")) {
			return <Navigate to={searchParams.get("ot") as string} />;
		} else {
			return <Navigate to="dashboard" />;
		}
	} else {
		return <Outlet />;
	}
};

export default PublicRoute;
