import { Button } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageContainer } from "components/pages/PageContainer";

import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Fa, FaButton } from "../../../styled/muiComponents";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { useCreateCourseMutation, useGetCoursesQuery } from "rtk/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingBubble } from "../Class/stageview/constants/styledComponents";
import { Course } from "rtk/types";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import { CardActionArea } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { theme } from "theme";

const Courses = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { data, error, isLoading, isSuccess, isError } = useGetCoursesQuery({
		pageSize: 10,
		page: 0,
		orderBy: "id",
		order: "ASC",
	});

	const [createCourse] = useCreateCourseMutation();

	const handleCreateCourse = async () => {
		await createCourse({
			name: "test123",
			description: "this is a test",
		});
	};

	const handleViewMaterials = (course: Course) => {
		console.log(course);
		navigate(`${course.id}`);
	};

	if (isLoading) {
		return <LoadingBubble />;
	}

	// if (data!.items.length === 0) {
	// 	return <p>{t("Daten erstellen")}</p>;
	// }

	return (
		<PageContainer maxWidth="lg">
			<Grid container direction="column">
				<Grid container direction="column">
					<Grid
						container
						item
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						mb={5}
					>
						<Typography variant="h1">{t("Courses")}</Typography>
						<Button
							color="primary"
							variant="contained"
							component={Link}
							disableElevation
							to="#"
							style={{ color: "white" }}
							size="small"
							onClick={handleCreateCourse}
						>
							<Fa
								icon={["far", "plus"]}
								fixedWidth
								style={{ marginRight: "4px" }}
							/>
							{t("Add Course")}
						</Button>
					</Grid>
					<Grid container direction="row" spacing={2}>
						{data!.items.map((row) => (
							<Grid item md={3}>
								<Card
									onClick={() => handleViewMaterials(row)}
									key={row.name}
								>
									<CardActionArea href={`#`}>
										<CardMedia
											sx={{ height: 140 }}
											image="https://picsum.photos/200/300"
											title="green iguana"
										/>
										<CardContent
											style={{
												textAlign: "center",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<Typography variant="h2">
												<strong>{row.name}</strong>
											</Typography>
											<Typography mt={0} variant="body1">
												{row.description}
											</Typography>
										</CardContent>
									</CardActionArea>
									<CardActions
										style={{
											display: "flex",
											justifyContent: "space-between",
											borderTop:
												"1px solid rgba(0, 0, 0, 0.12)",
										}}
									>
										<Button
											size="small"
											href="/realclassroom"
										>
											Invite
										</Button>
										<Button size="small">Stutends</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</Grid>
				{/*<Grid container direction="row" spacing={2}>
					<Grid item md={9}>
						<Paper elevation={0}>
							<TableContainer component={Paper}>
								<Table
									sx={{ minWidth: 650 }}
									aria-label="simple table"
								>
									<TableHead>
										<TableRow>
											<TableCell>{t("Name")}</TableCell>
											<TableCell align="right">
												{t("Beschreibung")}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data!.items.map((row) => (
											<TableRow
												onClick={() =>
													handleViewMaterials(row)
												}
												key={row.name}
												sx={{
													"&:last-child td, &:last-child th":
														{ border: 0 },
												}}
											>
												<TableCell
													component="th"
													scope="row"
												>
													{row.name}
												</TableCell>
												<TableCell align="right">
													{row.description}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
											</Grid>*/}
			</Grid>
		</PageContainer>
	);
};

export default Courses;
