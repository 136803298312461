import { useState, useRef, useEffect } from "react";
import { Stage } from "react-konva";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import PencilColor from "./constants/pencilColor";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
	selectStage,
	selectLoadablesFromStage,
} from "store/reducers/class/stages";
import useDraw from "./hooks/useDraw";
import usePing from "./hooks/usePing";
import { Point } from "types/common";
import { Role } from "../classRoom";

import Layer from "./components/Layer";
import Item from "./components/Item";
import Toolbar, { Tool } from "./components/Toolbar";
import { setTeacherTool } from "store/reducers/class/class";
import { Typography } from "styled/muiComponents";

const Scrollbar = styled(PerfectScrollbar)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "nowrap",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	maxHeight: "100vh",
}));

type Props = {
	stageId: string;
	scale: number;
	onZoomIn: () => void;
	onZoomOut: () => void;
	onZoomReset: () => void;
	userRole: any | null; // TODO: andre fix types
	studentTool: Tool | null;
	teacherTool: Tool;
};

type PropsWithNull = {
	stageId: string | null;
	scale: number;
	onZoomIn: () => void;
	onZoomOut: () => void;
	onZoomReset: () => void;
	userRole: any | null; // TODO: andre fix types
	studentTool: Tool | null;
	teacherTool: Tool;
};

const Slide = styled(Grid)(({ theme }) => ({
	width: "calc(25% - 8px)",
	maxWidth: "calc(25% - 8px) !important",
	flexBasis: "calc(25% - 8px) !important",
	"&:hover": {
		cursor: "pointer",
	},
	"& img": {
		width: "100%",
		border: "3px solid transparent",
		transition: "0.3s all ease",
		borderRadius: "5px",
	},
	"& img:hover": {
		border: "3px solid #ffffff",
	},
}));

const StageViewerWrapper = ({ stageId, userRole, ...props }: PropsWithNull) => {
	if (!stageId) {
		return (
			<div>
				{" "}
				<Typography color="white" variant="h1">
					{" "}
					No Slides{" "}
				</Typography>
				<Button> Add Slidecollection</Button>
			</div>
		);
	} else {
		return <StageViewer stageId={stageId} {...{ userRole, ...props }} />;
	}
};

const StageViewer = ({
	stageId,
	onZoomIn,
	onZoomOut,
	onZoomReset,
	scale,
	userRole,
	studentTool,
	teacherTool,
}: Props) => {
	const stage = useAppSelector(selectStage(stageId));
	const stageViewerRef = useRef<HTMLDivElement>(null);
	const [raw, setRaw] = useState({ width: 0, height: 0 });

	const { startDrawing, draw, stopDrawing, isDrawing, resetDrawing } =
		useDraw({
			stageId,
		});
	const { ping } = usePing({
		stageId,
		fill: userRole === Role.Teacher ? "red" : "green",
	});
	const tool = userRole === Role.Teacher ? teacherTool : studentTool;
	const dispatch = useAppDispatch();

	const [pencilColor, setPencilColor] = useState<PencilColor>(
		PencilColor.Red
	);

	//! width & heigt from ref.current.getBoundingClientRect()
	//!

	useEffect(() => {
		const handleResize = () => {
			if (stageViewerRef.current) {
				const { width, height } =
					stageViewerRef.current.getBoundingClientRect();

				const scaleWidth = width / stage.width;
				const scaleHeight = height / stage.height;

				const scale = Math.min(scaleWidth, scaleHeight);

				setRaw({
					height: stage.height * scale,
					width: stage.width * scale,
				});
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [raw.width, raw.height]);

	useEffect(() => {
		//TODO might be better to use a ref here
		if (isDrawing) {
			document.addEventListener("mouseup", stopDrawing);
		}

		return () => {
			if (isDrawing) {
				document.removeEventListener("mouseup", stopDrawing);
			}
		};
	}, [isDrawing]);

	const handleMouseDown = (e: any) => {
		switch (tool) {
			case Tool.Pen:
				{
					const { x, y }: Point = e.target
						.getStage()
						.getPointerPosition();
					startDrawing({
						point: { x, y },
						stroke: pencilColor,
						globalCompositeOperation: "source-over",
					});
				}
				break;
			case Tool.Eraser:
				{
					const { x, y }: Point = e.target
						.getStage()
						.getPointerPosition();
					startDrawing({
						point: { x, y },
						stroke: pencilColor,
						globalCompositeOperation: "destination-out",
						strokeWidth: 30,
					});
				}
				break;
			default: {
			}
		}
	};

	const handleMouseMove = (e: any) => {
		if (!isDrawing) {
			return;
		}
		const point: Point = e.target.getStage().getPointerPosition();
		draw(point);
	};

	const handleMouseUp = () => {
		if (isDrawing) {
			stopDrawing();
		}
	};

	const handleClick = (e: any) => {
		switch (tool) {
			case Tool.Pointer:
				{
					const point: Point = e.target
						.getStage()
						.getPointerPosition();
					ping(point);
				}
				break;
			/*case Tool.StickyNote: {
				const { x, y } = event.target.getStage().getPointerPosition();
				setTextElements([...textElements, { x, y }]);
				console.log( event.target)
			}*/
		}
	};

	const handleReset = () => {
		resetDrawing(); //TODO only drawing, sticky and later D&D has to be handled seperately
	};

	const handleToolChange = (tool: Tool) => {
		dispatch(setTeacherTool(tool));
	};

	return (
		<Grid
			container
			direction="row"
			wrap="nowrap"
			style={{ height: "100%" }}
		>
			<Toolbar
				tool={tool}
				studentTool={studentTool}
				onToolChange={handleToolChange}
				color={pencilColor}
				onColorChange={setPencilColor}
				onReset={handleReset}
				onZoomIn={onZoomIn}
				onZoomOut={onZoomOut}
				onZoomReset={onZoomReset}
				userRole={userRole}
			></Toolbar>

			<Scrollbar style={{ width: "100%", height: "100%" }}>
				<Grid
					ref={stageViewerRef}
					container
					item
					justifyContent="center"
					alignItems="center"
					direction="column"
					wrap="nowrap"
					style={{ transform: `scale(${scale})`, height: "100%" }}
				>
					<div
						style={{
							borderRadius: "5px",
							overflow: "hidden",
							height: "100%",
							width: "100%",
							position: "relative",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Stage
							width={raw.width}
							height={raw.height}
							onMouseDown={handleMouseDown}
							onMousemove={handleMouseMove}
							//onMouseup={handleMouseUp}
							onClick={handleClick}
							style={
								tool === Tool.Pen
									? {
											cursor: `url('/assets/cursor/pen/${pencilColor.slice(
												1
											)}.png') 0 25, auto`,
											borderRadius: "8px",
											overflow: "hidden",
									  }
									: tool === Tool.Eraser
									? {
											cursor: `url('/assets/circle-regular.png') 15 15, auto`,
											borderRadius: "8px",
											overflow: "hidden",
									  }
									: {
											cursor: "pointer",
											borderRadius: "8px",
											overflow: "hidden",
									  }
							}
						>
							{stage.layerIds.map((layerId) => (
								<Layer
									key={layerId}
									layerId={layerId}
									scale={{
										width:
											raw.width / stage.width > 1
												? 1
												: raw.width / stage.width,
										height:
											raw.height / stage.height > 1
												? 1
												: raw.height / stage.height,
									}}
								>
									{(itemIds: string[]) =>
										itemIds.map((itemId) => (
											<Item
												key={itemId}
												itemId={itemId}
												scale={{
													width:
														raw.width /
															stage.width >
														1
															? 1
															: raw.width /
															  stage.width,
													height:
														raw.height /
															stage.height >
														1
															? 1
															: raw.height /
															  stage.height,
												}}
											/>
										))
									}
								</Layer>
							))}
						</Stage>
					</div>
				</Grid>
			</Scrollbar>
		</Grid>
	);
};

export const StagePreview = ({
	stageId,
	onClick,
}: {
	stageId: string;
	onClick: (stageId: string) => void;
}) => {
	const stage = useAppSelector(selectStage(stageId));
	const loadables = useAppSelector(selectLoadablesFromStage(stageId));
	const uri = useRef<string | null>(null);
	const stageRef: any = useRef(null);

	useEffect(() => {
		if (
			stageRef.current &&
			loadables.every((loadable) => loadable.loadingState === "loaded") &&
			!uri.current
		) {
			uri.current = stageRef.current.getStage().toDataURL();
		}
	}, [loadables, stageRef.current]);

	if (uri.current === null) {
		return (
			<div style={{ position: "absolute", left: -1000, top: -1000 }}>
				<Stage width={stage.width} height={stage.height} ref={stageRef}>
					{stage.layerIds.map((layerId: string) => (
						<Layer key={layerId} layerId={layerId} />
					))}
				</Stage>
			</div>
		);
	} else {
		return (
			<Slide item md={2}>
				<Button
					style={{ width: "100%", height: "100%", padding: "0px" }}
				>
					<img
						src={uri.current}
						width="100%"
						height="auto"
						onClick={() => onClick(stage.id)}
						style={{ cursor: "pointer" }}
					/>
				</Button>
			</Slide>
		);
	}
};

export default StageViewerWrapper;
