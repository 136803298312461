import { useGetSlideCollectionQuery } from "rtk/api";
import { SlideCollection } from "rtk/types";
import SlideCollectionViewContainer, {
	SlideCollectionViewContainerWithId,
} from "./slide_collections/view/SlideCollectionView";
import { LoadingBubble } from "../Class/stageview/constants/styledComponents";

interface Props {
	material: SlideCollection; // currently only material
	// onClick: () => void;
	type: string; //expand when there are more material types
	style?: React.CSSProperties;
}

export const MaterialDialogDisplay = ({ material, type }: Props) => {
	switch (type) {
		case "SLIDECOLLECTION":
			return <SlideCollectionDisplay material={material} />;
		default:
			return <>error</>;
	}
};

const SlideCollectionDisplay = ({ material }: any) => {
	const { data: slideCollection, isLoading } = useGetSlideCollectionQuery(
		material.id
	);
	if (isLoading) {
		return <LoadingBubble />;
	}
	return <SlideCollectionViewContainerWithId id={material.id} />;
};
