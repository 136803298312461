import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	useCreateSlideCollectionMutation,
	useGetStudentsQuery,
	useGetStudentQuery,
	useUploadImageMutation,
} from "rtk/api";
import { useAppDispatch } from "store/hooks";
import { setNavbarState } from "store/reducers/app/reducer";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageContainer } from "components/pages/PageContainer";
import { Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Lottie from "lottie-react";

import eventAnimation from "assets/lottie/event-animation.json";
import { theme } from "theme";
import moment from "moment";
import { UpcommingLessons } from "routes/app/dashboard/UpcommingLessons";
import { LoadingBubble } from "routes/app/Class/stageview/constants/styledComponents";

const Student = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { id } = useParams();

	const { data: student, isLoading } = useGetStudentQuery(id as string);

	if (isLoading) {
		return <LoadingBubble />;
	}

	return (
		<PageContainer maxWidth="lg">
			<Grid container direction="column">
				<Typography variant="h1" mb={2}>
					{t("Students >")} {student?.firstName} {student?.lastName}
				</Typography>
				<Grid container direction="row" spacing={2}>
					<Grid item md={3}>
						<Card>
							<CardContent
								style={{
									textAlign: "center",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<Avatar
									style={{
										width: 70,
										height: 70,
										marginTop: theme.spacing(1.5),
									}}
								>
									{student?.firstName
										? student?.firstName[0]
										: student?.email[0]}
									{student?.lastName
										? student?.lastName[0]
										: student?.email[1]}
								</Avatar>
								<Typography mt={2} variant="h2">
									<strong>
										{student?.firstName} {student?.lastName}
									</strong>
								</Typography>
							</CardContent>
							<CardActions
								style={{
									display: "flex",
									justifyContent: "space-between",
									borderTop: "1px solid rgba(0, 0, 0, 0.12)",
								}}
							>
								<Button size="small" href="/classroom">
									Create Class
								</Button>
								<Button size="small">Write Message</Button>
							</CardActions>
						</Card>
					</Grid>
					<Grid item md={9}>
						<Card>
							<CardContent>
								<Typography mb={2} variant="h2">
									<strong>{t("Recurring Classes")}</strong>
								</Typography>
								<TableContainer>
									<Table
										sx={{ minWidth: 650 }}
										aria-label="simple table"
									>
										<TableHead>
											<TableRow>
												<TableCell>
													{t("Course")}
												</TableCell>
												<TableCell align="right">
													{t("Interval")}
												</TableCell>
												<TableCell align="right">
													{t("Day (Time)")}
												</TableCell>
												<TableCell align="right">
													{t("Progress")}
												</TableCell>
												<TableCell align="right">
													{t("Action")}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow
												sx={{
													"&:last-child td, &:last-child th":
														{ border: 0 },
												}}
											>
												<TableCell
													component="th"
													scope="row"
												>
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>

								<Typography mb={2} mt={4} variant="h2">
									<strong>{t("Single Classes")}</strong>
								</Typography>
								<TableContainer>
									<Table
										sx={{ minWidth: 650 }}
										aria-label="simple table"
									>
										<TableHead>
											<TableRow>
												<TableCell>
													{t("Course")}
												</TableCell>
												<TableCell align="right">
													{t("Class")}
												</TableCell>
												<TableCell align="right">
													{t("Date")}
												</TableCell>
												<TableCell align="right">
													{t("Time")}
												</TableCell>
												<TableCell align="right">
													{t("Action")}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow
												sx={{
													"&:last-child td, &:last-child th":
														{ border: 0 },
												}}
											>
												<TableCell
													component="th"
													scope="row"
												>
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
												<TableCell align="right">
													123
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
								<UpcommingLessons studentId={student?.id} />
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Student;
