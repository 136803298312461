import TimeLineCard from "./TimeLineCard";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import moment from "moment";
import { useGetLessonsQuery } from "rtk/api";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { Lesson } from "rtk/types";

import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Fa } from "styled/muiComponents";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
	padding: `${theme.spacing(3)}`,
	marginBottom: `${theme.spacing(2)}`,
	borderRadius: `${theme.spacing(2)}`,
	width: "100%",
}));

const sortData = (list: Lesson[]) => {
	const sortedData: Record<string, Lesson[]> = {};
	list.forEach((item) => {
		const date = item.startAt.split("T")[0];
		if (sortedData[date]) {
			sortedData[date].push(item);
		} else {
			sortedData[date] = [item];
		}
	});
	return sortedData;
};

interface Props {
	studentId?: number;
}

export const UpcommingLessons = ({ studentId }: Props) => {
	const [lessonsList, setLessonsList] = useState<Record<
		string,
		Lesson[]
	> | null>(null);

	const [page, setPage] = useState(1);
	const [emptyFetches, setEmptyFetches] = useState<number>(0);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleIncreasePage = () => {
		setPage((prev: number) => prev + 1);
	};

	const {
		data: lessons,
		isLoading,
		status,
	} = useGetLessonsQuery({
		studentId: studentId,
		from:
			moment()
				.add(14 * (page - 1), "days")
				.format("YYYY-MM-DDT00:00:00.000") + "Z",
		to:
			moment()
				.add(14 * page, "days")
				.format("YYYY-MM-DDT00:00:00.000") + "Z",
	});

	useEffect(() => {
		if (Array.isArray(lessons) && lessons !== undefined) {
			if (!lessonsList) {
				const newLessonsList: Record<string, Lesson[]> = {
					...sortData(lessons),
				};
				const sortedKeys = Object.keys(newLessonsList).sort(
					(a, b) => new Date(a).getTime() - new Date(b).getTime()
				);
				const sortedNewLessonsList = sortedKeys.reduce((acc, key) => {
					acc[key] = newLessonsList[key];
					return acc;
				}, {} as Record<string, Lesson[]>);
				setLessonsList(sortedNewLessonsList);
			} else {
				console.log(lessons);
				const newLessonsList: Record<string, Lesson[]> = {
					...lessonsList,
					...sortData(lessons),
				};
				const sortedKeys = Object.keys(newLessonsList).sort(
					(a, b) => new Date(a).getTime() - new Date(b).getTime()
				);

				const sortedNewLessonsList = sortedKeys.reduce((acc, key) => {
					acc[key] = newLessonsList[key];
					return acc;
				}, {} as Record<string, Lesson[]>);
				setLessonsList(sortedNewLessonsList);
			}
		}
	}, [lessons]);

	useEffect(() => {
		if (!lessons) {
			return;
		}
		if (Object.keys(lessons).length === 0) {
			setEmptyFetches((prev) => prev + 1);
		}
	}, [page]);

	const handleCreateLesson = () => {
		navigate("/timetable");
	};

	const handleStickyStateChange = () => {
		const listContainer = document.getElementById("LessonsList");
		if (listContainer) {
			const isNearBottom =
				listContainer.scrollTop + listContainer.clientHeight >=
				listContainer.scrollHeight * 0.8;

			if (isNearBottom) {
				if (status === "fulfilled" && emptyFetches < 3) {
					handleIncreasePage();
				}
			}
		}
	};

	if (isLoading) {
		return <p>Loading</p>;
	}

	if (!lessonsList) {
		return <p>no upcomming lessons</p>;
		//! check next couple of weeks aswell. maybe fetch a month to begin with?
	}

	return (
		<PerfectScrollbar
			style={{
				height: "100%",
				maxHeight: "calc(100vh - 130px)",
				zIndex: "1000",
				width: "100%",
			}}
		>
			{Object.keys(lessonsList).length > 1 ? (
				<List
					id="LessonsList"
					sx={{
						// width: "100%",
						// maxWidth: 360,
						//bgcolor: "background.paper",
						position: "relative",
						//overflow: "hidden",
						//maxHeight: "calc(100vh - 130px)",
						height: "auto",
						width: "100%",
						"& ul": { padding: 0 },
					}}
					onScroll={handleStickyStateChange}
					subheader={<li />}
				>
					{Object.keys(lessonsList).map(
						(key: string, idx: number) => {
							const title =
								moment(key).format("ddd. DD. MMM. YYYY");

							return (
								<>
									<ListSubheader
										sx={{
											padding: 0,
											paddingBottom: "16px",
											background: "#f2f2f2",
											color: "#000",
										}}
									>
										<Typography variant="h2" mb={1}>
											{title}
										</Typography>
									</ListSubheader>
									{/* @ts-ignore */}
									{lessonsList[key].map((lesson: Lesson) => (
										<ListItem sx={{ padding: 0 }}>
											<TimeLineCard lesson={lesson} />
										</ListItem>
									))}
								</>
							);
						}
					)}
					{emptyFetches >= 3 && (
						<ListItem>
							<Typography variant="h2" mb={3} mt={4}>
								{t("No more upcomming lessons")}
							</Typography>
						</ListItem>
					)}
				</List>
			) : (
				<>
					<ListSubheader
						sx={{
							padding: 0,
							paddingBottom: "16px",
							background: "#f2f2f2",
							color: "#000",
						}}
					>
						<Typography variant="h2" mb={1}>
							{t("No lessons available")}
						</Typography>
					</ListSubheader>
					<ListItem sx={{ padding: 0 }}>
						<StyledCard elevation={0} variant="outlined">
							<Typography variant="h5">
								{t("No lessons available")}
							</Typography>
							<Button
								color="primary"
								variant="contained"
								disableElevation
								style={{ color: "white" }}
								size="small"
								onClick={handleCreateLesson}
							>
								<Fa
									icon={["far", "plus"]}
									fixedWidth
									style={{ marginRight: "4px" }}
								/>
								{t("Create lesson")}
							</Button>
						</StyledCard>
					</ListItem>
				</>
			)}
		</PerfectScrollbar>
	);
};
