import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image, Line, Rect, Group, Circle } from 'react-konva';
import useImage from 'use-image';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Fa, FaButton, Typography } from '../../../styled/muiComponents.tsx'
import { v4 as uuid } from 'uuid';

const URLImage = ({ image }) => {
	const [img] = useImage(image.src);
	return (
		<Image
			image={img}
			x={image.x}
			y={image.y}
			// I will use offset to set origin to the center of the image
			offsetX={img ? img.width / 2 : 0}
			offsetY={img ? img.height / 2 : 0}
			draggable
		/>
	);
};

const Playground2 = () => {
	const dragUrl = React.useRef();
	const stageRef = React.useRef();
	const [images, setImages] = React.useState([]);

	const [tool, setTool] = React.useState('pen');
	const [lines, setLines] = React.useState([]);
	const [pencilColor, setPencilColor] = React.useState('#d91e18');
	const isDrawing = React.useRef(false);

	const [image, setImage] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [imageX, setImageX] = useState(5);
	const [imageY, setImageY] = useState(430);
	const [isDragging, setIsDragging] = useState(false);
	const imageRef = useRef(null);
	const backgroundRef = useRef(null);
	const layerRef = React.useRef(null);
	const [previewImageSrc, setPreviewImageSrc] = useState(null);

	const [circles, setCircles] = useState([]);

	const colorPalette = {
		red: "#d91e18",
		orange: "#e67e22",
		yellow: "#f9bf3b",
		green: "#2ecc71",
		blue: "#19b5fe",
		navi: "#1f3a93",
		pink: "#f62496",
		purple: "#9a12b3",
		brown: "#96411b",
		grey: "#aeb8b8",
		white: "#ffffff",
		black: "#000000"
	}


	const handleMouseDown = (e) => {
		isDrawing.current = true;
		const pos = e.target.getStage().getPointerPosition();
		setLines([...lines, { tool, points: [pos.x, pos.y], color: pencilColor }]);
	};

	const handleMouseMove = (e) => {
		// no drawing - skipping
		if (!isDrawing.current) {
			return;
		}
		const stage = e.target.getStage();
		const point = stage.getPointerPosition();
		let lastLine = lines[lines.length - 1];
		// add point
		lastLine.points = lastLine.points.concat([point.x, point.y]);

		// replace last
		lines.splice(lines.length - 1, 1, lastLine);
		setLines(lines.concat());
	};

	const handleMouseUp = () => {
		isDrawing.current = false;
	};

	const changePencilColor = (newColor) => {
		setPencilColor(newColor);
	};


	const handleClearLines = () => {
		setLines([]);
	};



	const handleClick = (event) => {
		const id = uuid()
		const newCircle = {
			id,
			x: event.evt.offsetX,
			y: event.evt.offsetY,
			radius: 20,
			fill: "red"
		};

		console.log([newCircle.x, newCircle.y])

		setCircles(prev => [...prev, newCircle]);

		setTimeout(() => {
			setCircles((prevCircles) => prevCircles.filter(c => c.id !== id));
		}, 1750);
	};

	useEffect(() => {
		const img = new window.Image();
		const backgroundImg = new window.Image();
		img.src = "/assets/H7lX6YjeQ1-pXYfe8qiH3g.png";
		img.onload = () => {
			setImage(img);
		};
		backgroundImg.src = "/assets/qz2kMZjaRvqoAqDc7hu5nw.jpg";
		backgroundImg.onload = () => {
			setBackgroundImage(backgroundImg);
		};
	}, []);

	useEffect(() => {
		const stage = stageRef.current;
		stage.toDataURL({
			mimeType: 'image/jpeg',
			quality: 0.8,
			callback: function (dataUrl) {
				setPreviewImageSrc(dataUrl);
			},
		});
	}, [image, backgroundImage, stageRef])


	const handleDragStart = () => {
		setIsDragging(true);
	};

	const handleDragEnd = () => {
		setIsDragging(false);
		const stage = stageRef.current;
		const dropZone = stage.findOne("#drop-zone");

		const imageX = imageRef.current.x() + imageRef.current.width() / 2;
		const imageY = imageRef.current.y() + imageRef.current.height() / 2;
		const audio = new Audio("/assets/audio/success.mp3");

		if (
			imageX >= dropZone.x() &&
			imageX <= dropZone.x() + dropZone.width() &&
			imageY >= dropZone.y() &&
			imageY <= dropZone.y() + dropZone.height()
		) {
			imageRef.current.remove();
			audio.play();
		}
	};


	const [showImage, setShowImage] = React.useState(true);
	const handleReset = () => {
		const stage = stageRef.current;
		const imageGroup = stage.findOne("#create-image-group");
		imageGroup.removeChildren();
		setLines([]);
		setShowImage(false);
		setTimeout(() => {
			setShowImage(true);
		}, 0);
	};

	return (
		<Grid container direction="column" justify="center" alignItems="center" p={2}>
			<Grid container item style={{ width: "910px" }} direction="column">
				<Grid container item direction="row" justify="center" alignItems="center" gap={3}>
					<Grid item>
						<img
							alt="lion"
							src="https://konvajs.org/assets/lion.png"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/200px/182022"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/150px/164041"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/200px/291713"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/150px/58321"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/200px/312780"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
					<Grid item>
						<img
							alt="lion"
							style={{ maxWidth: "100px" }}
							src="https://openclipart.org/image/150px/186971"
							height="70"
							draggable="true"
							onDragStart={(e) => {
								dragUrl.current = e.target.src;
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					item
					direction="column"
					onDrop={(e) => {
						e.preventDefault();
						// register event position
						stageRef.current.setPointersPositions(e);
						// add image
						setImages(prev => [...prev, {
							...stageRef.current.getPointerPosition(),
							src: dragUrl.current,
						}]);
					}}
					onDragOver={(e) => e.preventDefault()}
				>
					<Stage
						width={910}
						height={683}
						style={{ border: '1px solid grey' }}
						ref={stageRef}
						onMouseDown={handleMouseDown}
						onMousemove={handleMouseMove}
						onMouseup={handleMouseUp}
						onClick={handleClick}
					>
						<Layer>
							<Image
								x={0}
								y={0}
								image={backgroundImage}
								ref={backgroundRef}
								width={910}
								height={683}
							/>
						</Layer>
						<Layer ref={layerRef}>
							<Rect
								id="drop-zone"
								x={250}
								y={340}
								width={250}
								height={250}
								fill="green"
							/>
							{lines.map((line, i) => (
								<Line
									key={i}
									points={line.points}
									stroke={line.color || pencilColor}
									strokeWidth={line.tool === 'eraser' ? 50 : 5}
									tension={0.5}
									lineCap="round"
									lineJoin="round"
									globalCompositeOperation={
										line.tool === 'eraser' ? 'destination-out' : 'source-over'
									}
								/>
							))}
							<Group id="create-image-group">
								{images.map((image, i) => {
									return <URLImage key={i} image={image} />;
								})}
							</Group>


							{showImage && (
								<Image
									x={imageX}
									y={imageY}
									image={image}
									width={250}
									height={250}
									ref={imageRef}
									draggable
									onDragStart={handleDragStart}
									onDragEnd={handleDragEnd}
								/>
							)}
						</Layer>
						<Layer>
							{circles.map(({ x, y, radius, fill, id }) =>
								<DisappearingCircle key={id} x={x} y={y} radius={radius} fill={fill} />
							)}
						</Layer>
					</Stage>
					<Grid item>
						<img src={previewImageSrc} height={75} />
					</Grid>
					<Grid container item direction="row">
						<Button onClick={(e) => { setTool(e.target.value); }} value="pen" variant='contained' disableElevation style={{ borderRadius: 0, minHeight: "40px" }}><Fa icon={["fas", "fa-pen"]} fixedWidth /></Button>
						<Button onClick={(e) => { setTool(e.target.value); }} value="eraser" variant='contained' disableElevation style={{ borderRadius: 0 }}><Fa icon={["far", "fa-eraser"]} fixedWidth /></Button>
						<Button onClick={handleClearLines} variant='contained' disableElevation style={{ borderRadius: 0 }}><Fa icon={["far", "fa-xmarks-lines"]} fixedWidth /></Button>
						<Button onClick={handleReset} variant='contained' disableElevation style={{ borderRadius: 0 }}><Fa icon={["fas", "fa-rotate-left"]} fixedWidth /></Button>
					</Grid>
					<Grid container item wrap='nowrap' direction="row" gap="1px" style={{ border: "1px solid #000" }}>
						{Object.entries(colorPalette).map(([colorName, colorValue], index) => (
							<Button
								variant="contained" disableElevation
								onClick={() => { changePencilColor(colorValue); }}
								style={{ backgroundColor: colorValue, borderRadius: 0, minHeight: "30px", flexGrow: 1 }}
							>
							</Button>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Grid >

	);
};

const DisappearingCircle = ({ delay, ...circleProps }) => {
	const ref1 = useRef(null)
	const ref2 = useRef(null)

	useEffect(() => {
		let t = setTimeout(() => {
			const circle = ref1.current;
			circle.to({
				duration: 0.5,
				scaleX: 0.1,
				scaleY: 0.1,
				onFinish: () => {
					circle.to({
						duration: 0.5,
						scaleX: 1,
						scaleY: 1,
						onFinish: () => {
							circle.to({
								duration: 0.5,
								scaleX: 0.1,
								scaleY: 0.1,
								onFinish: () => {
									circle.to({
										duration: 0.25,
										scaleX: 0.5,
										scaleY: 0.5,
									});
								},
							});
						},
					});
				},
			});
		}, delay)
		return () => clearTimeout(t)
	}, [ref1])

	useEffect(() => {
		let t = setTimeout(() => {
			const circle = ref2.current;
			circle.to({
				duration: 0.5,
				scaleX: 1,
				scaleY: 1,
				onFinish: () => {
					circle.to({
						duration: 0.5,
						scaleX: 0.1,
						scaleY: 0.1,
						onFinish: () => {
							circle.to({
								duration: 0.5,
								scaleX: 1,
								scaleY: 1,
								onFinish: () => {
									circle.to({
										duration: 0.25,
										scaleX: 0.5,
										scaleY: 0.5,
									});
								},
							});
						},
					});
				},
			});
		}, delay)
		return () => clearTimeout(t)
	}, [ref2])

	return (
		<>
			<Circle opacity={0.5} {...circleProps} ref={ref1} />
			<Circle opacity={0.5} scaleX={0.1} scaleY={0.1} {...circleProps} ref={ref2} />
		</>
	)
	// <Circle opacity={0.5} scale={0.1} {...circleProps} ref={ref2} />

}

export default Playground2;