import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faGaugeHigh,
    faGraduationCap,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory
 } from '@fortawesome/pro-thin-svg-icons'

library.add(
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faGaugeHigh,
    faGraduationCap,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory
    )
