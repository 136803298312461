enum PencilColor {
	Red = "#d91e18",
	Orange = "#e67e22",
	Yellow = "#f9bf3b",
	Green = "#2ecc71",
	Blue = "#19b5fe",
	Navi = "#1f3a93",
	Pink = "#f62496",
	Purple = "#9a12b3",
	Brown = "#96411b",
	Grey = "#aeb8b8",
	White = "#ffffff",
	Black = "#000000"
};

export default PencilColor;