import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPen,
	faRotateLeft,
	faSquareDashed,
	faCameraWebSlash,
	faStar,
	faPlay,
	faPause,
	faRightFromBracket,
	faRightToBracket,
	faShuffle,
	faFolder,
	faXmark,
	faChalkboard,
	faFolderClosed,
	faFolderOpen,
	faArrowRight,
	faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faPen,
	faRotateLeft,
	faSquareDashed,
	faCameraWebSlash,
	faStar,
	faPlay,
	faPause,
	faRightFromBracket,
	faRightToBracket,
	faShuffle,
	faFolder,
	faXmark,
	faChalkboard,
	faFolderClosed,
	faFolderOpen,
	faArrowRight,
	faArrowLeft
);
