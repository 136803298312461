import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PerfectScrollbar from "react-perfect-scrollbar";
import Fade from "@mui/material/Fade";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const SlidesWrapper = styled(Grid)(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	height: "100%",
	gap: "10px",
	position: "fixed",
	width: "80%",
	maxHeight: "calc(100vh - 60px)",
	overflow: "hidden",
	left: "0px",
	bottom: "0px",
	margin: "0px !important",
	paddingBottom: "15px",
	paddingRight: theme.spacing(0),
	backgroundColor: theme.palette.primary.main,
	boxSizing: "border-box",
	zIndex: 100,
}));
const Scrollbar = styled(PerfectScrollbar)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	gap: "10px",
	width: "100%",
	maxHeight: "100vh",
	overflow: "auto",
	paddingRight: "15px",
	paddingLeft: "14px",
	borderRadius: "5px",
}));
const Slide = styled(Grid)(({ theme }) => ({
	width: "calc(25% - 8px)",
	maxWidth: "calc(25% - 8px) !important",
	flexBasis: "calc(25% - 8px) !important",
	"&:hover": {
		cursor: "pointer",
	},
	"& img": {
		width: "100%",
		border: "3px solid transparent",
		transition: "0.3s all ease",
		borderRadius: "5px",
	},
	"& img:hover": {
		border: "3px solid #ffffff",
	},
}));
const ImgButton = styled(Button)(({ theme }) => ({
	height: "100%",
	aspectRatio: "4/3",
	overflow: "hidden",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "0px",
}));
interface Image {
	id: string;
	urls: {
		small: string;
		regular: string;
		full: string;
	};
}

type ImageSearchProps = {
	// searchImage: (query: string) => Promise<void>;
	// setBackgroundImage: (url: string) => void;
	// images: Image[];
	// selectImage: (image: Image) => void;
	// showBackground: boolean;
	// setSearchQuery: (query: string) => void;
	// setPage: (page: number) => void;
	// increasePage: () => void;

	setBackgroundImage: (url: string) => void;
	images: Image[];
	showBackground: boolean;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};

const ImageSearch: React.FC<ImageSearchProps> = ({
	setBackgroundImage,
	images,
	showBackground,
	setPage,
}) => {
	const { t } = useTranslation();
	const handleClick = (imageUrl: string) => {
		setBackgroundImage(imageUrl);
	};

	return (
		<Fade in={showBackground}>
			<SlidesWrapper container>
				<Scrollbar
					options={{ wheelPropagation: true, suppressScrollX: true }}
				>
					<Grid
						container
						columns={{ xs: 4, sm: 8, md: 12 }}
						wrap="wrap"
						gap="10px"
						direction="row"
						justifyItems="flex-start"
						alignItems="flex-start"
					>
						{images.map((image) => (
							<Slide item md={2}>
								<ImgButton>
									<img
										key={image.id}
										src={image.urls.small}
										alt=""
										onClick={() =>
											handleClick(image.urls.full)
										}
									/>
								</ImgButton>
							</Slide>
						))}
						<button onClick={() => setPage((prev) => prev + 1)}>
							{t("Mehr Laden")}
						</button>
					</Grid>
				</Scrollbar>
			</SlidesWrapper>
		</Fade>
	);
};

export default ImageSearch;
