import { SocketAddress } from "net";
import { Middleware } from "redux";
import {
	connected,
	joinedRoom,
	disconnected,
} from "store/reducers/class/class";

enum MessageKind {
	JoinedRoom = "joined_room",
	Disconnected = "disconnected",
	Action = "action",
	Error = "error",
	Connected = "connected",
	Pong = "pong",
}

type Meta = {
	sender: {
		id: string;
		username: string;
	};
	sentAt: Date;
};

type JoinedRoomMessage = { kind: MessageKind.JoinedRoom };
type DisconnectedMessage = { kind: MessageKind.Disconnected };
type ActionMessage = { kind: MessageKind.Action; payload: any };
type ConnectedMessage = { kind: MessageKind.Connected };
type ErrorMessage = { kind: MessageKind.Error; payload: string };
type PongMessage = { kind: MessageKind.Pong };

type Message = { meta: Meta } & (
	| JoinedRoomMessage
	| DisconnectedMessage
	| ActionMessage
	| ConnectedMessage
	| ErrorMessage
	| PongMessage
);

const listen = (store: any, socket: WebSocket) => {
	let intervalId = setInterval(() => {
		socket.send(
			JSON.stringify({
				kind: "ping",
				payload: {},
			})
		);
	}, 20000);

	socket.onmessage = (msg: any) => {
		const message: Message = JSON.parse(msg.data);
		console.log(message);

		const itsMe = (clientId: string) =>
			clientId === store.getState().class.wsClientId;

		switch (message.kind) {
			case MessageKind.JoinedRoom:
				store.dispatch(joinedRoom(message.meta.sender));
				break;
			case MessageKind.Connected:
				{
					const { meta } = message;
					store.dispatch(connected(meta.sender.id));
				}
				break;
			case MessageKind.Action:
				{
					const { payload, meta } = message;
					if (itsMe(meta.sender.id)) return;

					const { action } = payload;

					store.dispatch({ type: "syntetic", payload: action });
				}
				break;
			case MessageKind.Disconnected:
				{
					const { meta } = message;
					store.dispatch(disconnected(meta.sender.id));
				}
				break;
			case MessageKind.Pong:
				{
				}
				break;
			default: {
				console.error("Unhandled WsMessgaeKind: ", message);
			}
		}
	};

	socket.onclose = () => {
		store.dispatch(disconnected(store.getState().class.wsClientId));
		clearInterval(intervalId);
	};
};

const classWsMiddleware: Middleware = (store) => {
	let socket: WebSocket;

	return (next) => (action) => {
		const connectionState = store.getState().class.connectionState;
		console.log(connectionState);
		switch (action.type) {
			case "class/startConnecting":
				if (connectionState === "none") {
					//socket = new WebSocket(process.env.REACT_APP_SOCKET_URL as any)
					socket = new WebSocket(
						"wss://classeru-api.herokuapp.com/ws"
					);
					socket.onopen = () => {
						listen(store, socket);
					};
				}
				break;
			case "class/startJoiningRoom":
				{
					if (connectionState !== "connected")
						throw new Error("Not connected");
					const roomId = action.payload;
					socket.send(
						JSON.stringify({
							kind: "join_room",
							payload: {
								roomId,
								username:
									store.getState().session.user.username,
							},
						})
					);
				}
				break;
			case "class/startTimer":
			case "class/stopTimer":
			case "class/setTimer":
			case "class/setStars":
			case "item/addPoints":
			case "class/nextStage":
			case "class/prevStage":
			case "item/remove":
			case "class/goToStage":
			case "item/create":
			case "class/changeView":
			case "stage/createWhiteboard":
			case "class/goToWhiteboard":
			case "class/nextWhiteboard":
			case "class/prevWhiteboard":
			case "class/setStudentTool":
			case "class/setEqualState":
				if (connectionState !== "connected")
					throw new Error("Not connected");
				socket.send(
					JSON.stringify({
						kind: "action",
						payload: {
							roomId: store.getState().class.roomId,
							action,
						},
					})
				);
				break;
			case "syntetic": {
				action = action.payload;
				console.log("Transformed action: ", action);
			}
		}

		next(action);
	};
};

export default classWsMiddleware;
