import { PageContainer } from "components/pages/PageContainer";
import { useParams } from "react-router";
import { useGetCourseQuery } from "rtk/api";
import Explorer from "components/explorer/Explorer";
import { LoadingBubble } from "routes/app/Class/stageview/constants/styledComponents";

export const Units = () => {
	const { course_id } = useParams();

	const { data: course, isLoading } = useGetCourseQuery({
		id: parseInt(course_id!),
	});

	if (isLoading) {
		return <LoadingBubble />;
	}

	return (
		<PageContainer maxWidth="false">
			<Explorer folderId={course!.materialFolderId} />
		</PageContainer>
	);
};
