import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { create as createItem, addPoints, ItemType } from 'store/reducers/class/items';
import { selectLayer, create as createLayer } from 'store/reducers/class/layers';
import { remove as removeItem } from 'store/reducers/class/items';
import { LineCap, LineJoin } from 'konva/lib/Shape';
import { Point } from 'types/common';

const drawLayerId = (stageId: string) => stageId + "-draw";

const useDraw = ({ stageId }: { stageId: string }) => {
	const [currentLine, setCurrentLine] = useState<string | null>(null);
	const dispatch = useAppDispatch();
	const layerId = drawLayerId(stageId);
	const layer = useAppSelector(selectLayer(layerId))

	useEffect(() => {
		if(!layer) {
			dispatch(createLayer({ stageId, layerId }))
		}
	}, [ stageId, dispatch, layer, layerId ])

	const start = ({point, stroke, strokeWidth, globalCompositeOperation, lineCap, lineJoin, tension}: { point: Point, stroke: string, strokeWidth?:number, globalCompositeOperation: GlobalCompositeOperation, lineCap?: LineCap, lineJoin?: LineJoin, tension?: number} ) => {
		let lineId = uuid();
		setCurrentLine(lineId);
		dispatch(createItem({stageId, layerId, item: {
			id: lineId,
			type: ItemType.Line,
			points: [point.x, point.y],
			strokeWidth: strokeWidth || 5,
			stroke,
			globalCompositeOperation,
			lineCap: lineCap || "round",
			lineJoin: lineJoin || "round",
			tension: tension || 0.5
		}}))
	}

	const draw = (point: Point) => {
		if(!currentLine) throw new Error("cannot use draw when drawing is not started before")
        //TODO slow down events somehow!
		dispatch(addPoints({
			id: currentLine,
			point: [point.x, point.y]
		}))
	}

	const stop = () => {
		setCurrentLine(null)
	}

	const resetDrawing = () => {
		dispatch(removeItem(layer.itemIds))
	}

	return {
		startDrawing: start,
		draw,
		stopDrawing: stop,
		isDrawing: currentLine !== null,
		resetDrawing
	}
}

export default useDraw;
