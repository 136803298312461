import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
  create as createItem,
  remove as removeItem,
  ItemType,
} from "store/reducers/class/items";
import {
  create as createLayer,
  selectLayer,
} from "store/reducers/class/layers";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Point } from "types/common";

const pingLayerId = (stageId: string) => stageId + "-ping";

const usePing = ({
  stageId,
  delay,
  fill,
}: {
  stageId: string;
  delay?: number;
  fill: string;
}) => {
  const layerId = pingLayerId(stageId);
  const layer = useAppSelector(selectLayer(layerId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!layer) {
      dispatch(createLayer({ stageId, layerId }));
    }
  }, [stageId, dispatch, layerId, layer]);

  const ping = (point: Point) => {
    let id = uuid();
    console.log(point);
    dispatch(
      createItem({
        stageId,
        layerId,
        item: {
          id,
          type: ItemType.Ping,
          x: point.x,
          y: point.y,
          fill,
        },
      })
    );

    setTimeout(() => {
      dispatch(removeItem(id));
    }, delay || 1750);
  };

  return {
    ping,
  };
};

export default usePing;
