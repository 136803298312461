import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AppState = {
	navbarState: "open" | "closed";
};

const initialState: AppState = {
	navbarState: "open",
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setNavbarState: (
			state,
			action: PayloadAction<AppState["navbarState"]>
		) => {
			state.navbarState = action.payload;
		},
	},
});

export const { setNavbarState } = appSlice.actions;

export default appSlice.reducer;
