import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Fa } from "../../../../styled/muiComponents";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import {
  selectClass,
  setTimer,
  startTimer,
  stopTimer,
} from "store/reducers/class/class";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface CountdownProps {
  initialTime: number;
}
const CountDownButton = styled(Button)(({ theme }) => ({
  height: "30px",
  transition: "all 0.1s ease",
  minWidth: "10px !important",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));
const Countdown: React.FC<CountdownProps> = ({ initialTime }) => {
  const dispatch = useAppDispatch();
  const {
    timer: { isRunning, time },
  } = useAppSelector(selectClass);

  const formatTime = (time: number) => {
    const isNegative = time < 0;
    const absTime = Math.abs(time);
    const minutes = Math.floor(absTime / 60);
    const seconds = absTime % 60;

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${isNegative ? "-" : ""}${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    dispatch(setTimer(initialTime));
  }, []);

  const start = () => {
    dispatch(startTimer());
  };

  const stop = () => {
    dispatch(stopTimer());
  };

  const resetTimer = () => {
    dispatch(setTimer(initialTime));
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{
          border: "1px solid rgba(255, 255, 255, 0.15)",
          borderRadius: "15px",
        }}
      >
        <Tooltip title={isRunning ? "Pause" : "Start"} placement="bottom" TransitionComponent={Zoom}>
          <CountDownButton
            onClick={isRunning ? stop : start}
            variant="contained"
            size="small"
            disableElevation
          >
            <Fa
              icon={isRunning ? ["fas", "pause"] : ["fas", "play"]}
              fixedWidth
              style={{ marginRight: "8px" }}
            />
            <span
              style={
                time <= 5 * 60
                  ? { color: "red"}
                  : { }
              }
            >
              {formatTime(time)}
            </span>
          </CountDownButton>
        </Tooltip>
        <Tooltip title={"Reset"} placement="bottom" TransitionComponent={Zoom}>
          <CountDownButton
            onClick={resetTimer}
            variant="contained"
            size="small"
            disableElevation
            style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.15)" }}
          >
            <Fa icon={["fas", "rotate-left"]} fixedWidth />
          </CountDownButton>
        </Tooltip>
      </ButtonGroup>
    </div>
  );
};

export default Countdown;
