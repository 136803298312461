import MuiGrid from '@mui/material/Grid';
import MuiTypography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiBox from '@mui/material/Box';
import MuiDivider from '@mui/material/Divider';
import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import MuiFormikInput from "components/formik/FormikInput"
import { styled } from '@mui/material/styles';
import { palette, compose, spacing, sizing, textAlign } from '@mui/system';

export const Grid = styled(MuiGrid)(compose(spacing, sizing, textAlign));
export const Typography = styled(MuiTypography)(spacing)
export const Fa = styled(FontAwesomeIcon)(compose(spacing, palette));
export const FaButton = styled(IconButton)(compose(spacing, sizing, textAlign));
export const Divider = styled(MuiDivider)(spacing);
export const Box = styled(MuiBox)(spacing);
export const Button = styled(MuiButton)(spacing);
export const Text = styled(TextField)(compose(spacing, sizing, palette));
//export const FormikInput = styled(MuiFormikInput)(spacing); 