import { useEffect, useState } from "react";
import ClassRoom from "./classRoom";
import { useLoaderData } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectUser } from "store/reducers/session";
import {
	RawClass,
	selectClass,
	startConnecting,
	startJoiningRoom,
} from "store/reducers/class/class";
import { useGetClassroomInfoQuery, useGetSlideCollectionsQuery } from "rtk/api";
import { Slide } from "rtk/types";
import {
	ClassRoomContainer,
	LoadingBubble,
} from "./stageview/constants/styledComponents";
import Grid from "@mui/material/Grid";
import { Typography } from "../../../styled/muiComponents";

const ClassRoomWrapper = () => {
	const uuid = useLoaderData() as string;
	const dispatch = useAppDispatch();
	const { connectionState, roomConnectionState } =
		useAppSelector(selectClass);
	const user = useAppSelector(selectUser);

	const [rawClass, setRawClass] = useState<RawClass | null>(null);
	const { data: classroomInfo, isLoading } = useGetClassroomInfoQuery(uuid);
	const { data: slideCollections } = useGetSlideCollectionsQuery();

	console.log(classroomInfo);

	useEffect(() => {
		if (
			connectionState !== "connected" &&
			connectionState !== "connecting"
		) {
			dispatch(startConnecting());
		}
	}, [connectionState, dispatch, startConnecting]);

	useEffect(() => {
		if (
			user &&
			connectionState === "connected" &&
			!["connecting", "connected"].includes(roomConnectionState)
		) {
			dispatch(startJoiningRoom(uuid));
		}
	}, [
		connectionState,
		roomConnectionState,
		dispatch,
		startJoiningRoom,
		user,
		uuid,
	]);

	useEffect(() => {
		if (isLoading || !classroomInfo) return;
		setRawClass({
			stages: classroomInfo.slides.map((slide: Slide) => slide.stage),
			teacher: { id: classroomInfo.teacherId },
			student: classroomInfo.studentId
				? { id: classroomInfo.studentId }
				: null,
		});
	}, [
		isLoading,
		classroomInfo?.studentId,
		classroomInfo?.teacherId,
		classroomInfo?.slides,
	]);

	const handleAddSlideCollection = (values: any) => {
		console.log("SUBMIT: ", values);
	};

	if (
		!user ||
		connectionState !== "connected" ||
		roomConnectionState !== "connected" ||
		!rawClass ||
		isLoading
	) {
		return (
			<ClassRoomContainer
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				style={{ textAlign: "center", color: "#ffffff" }}
			>
				<img
					src="/assets/class-guru.svg"
					alt="Classuru"
					style={{ width: "100%", maxWidth: "100px" }}
				/>
				<Grid
					container
					item
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<LoadingBubble item>
						<Typography
							variant="h1"
							style={{ lineHeight: 1 }}
							mt={0}
							mb={1}
						>
							Connecting & Loading Classroom
						</Typography>
						<Typography variant="body1">
							This will take a while, classroom is being loaded
						</Typography>
					</LoadingBubble>
				</Grid>
			</ClassRoomContainer>
		);
		/*} else if (
		classroomInfo?.teacherId === user.id &&
		classroomInfo?.slides.length === 0
	) {
		return (
			<div>
				<Typography variant="h1">No slides</Typography>
				<Typography variant="body1">Please add some slides</Typography>
				<Form onSubmit={handleAddSlideCollection}>
					{({ handleSubmit, values }) => (
						<form id="" onSubmit={handleSubmit}>
							<Field name="slideCollectionOption">
								{(props) => (
									<Autocomplete
										disablePortal
										options={
											slideCollections
												? slideCollections
												: []
										}
										onChange={(a, b) => {
											props.input.onChange(b);
										}}
										getOptionLabel={(option) => option.name}
										value={props.input.value || null}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Slide Collection"
											/>
										)}
									/>
								)}
							</Field>
						</form>
					)}
				</Form>
			</div>
		);*/
	} else {
		return <ClassRoom rawClass={rawClass} />;
	}
};

export default ClassRoomWrapper;
