import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { useValidation } from "components/form/validators";
import { useTranslation } from "react-i18next";
import {
	useGetLessonInviteQuery,
	useGetLessonQuery,
	useIsValidLessonInvitationTokenMutation,
	useLessonInviteRespondMutation,
} from "rtk/api";
import {
	Link,
	LoaderFunctionArgs,
	Navigate,
	useLoaderData,
	useLocation,
	useSearchParams,
} from "react-router-dom";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { Button, Grid } from "styled/muiComponents";
import {
	Lesson,
	LessonInvite as ILessonInvite,
	SetPasswordCredentials,
} from "rtk/types";
import { tokenLoader } from "routes/router";
import { useAppSelector } from "store/hooks";
import { selectSession } from "store/reducers/session";
import { encode, decode } from "util/hashids";

type LoaderRet = {
	token: string;
	response: "accept" | "reject";
};
export const lessonInviteLoader = ({
	params,
}: LoaderFunctionArgs): LoaderRet => {
	return {
		token: String(params.token),
		response: String(params.response) as "accept" | "reject",
	};
};

const LessonInviteAccepted = ({ lessonId }: { lessonId: number }) => {
	const {
		data: lesson,
		isLoading,
		isError,
		isSuccess,
		error,
	} = useGetLessonQuery(lessonId);
	//mutation to actually accept / reject
	if (isLoading) {
		return <Typography>loading Lesson information...</Typography>;
	}
	if (isError) {
		return (
			<Typography>
				error loading Lesson information...{" "}
				{JSON.stringify(error, null, 2)}
			</Typography>
		);
	}
	if (isSuccess) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Great, you have accepted to participate {lesson.name}
					</Typography>
					<Typography>Teacher: {lesson.description}</Typography>
					<Typography>StartsAt {lesson.startAt}</Typography>
					<Typography>EndsAt {lesson.endAt}</Typography>
					<Link to={`/classroom/${lesson.classroom}`}>Join</Link>
				</Grid>
				<Grid item xs={12}>
					<Link to="/">Back to home</Link>
				</Grid>
			</Grid>
		);
	}
	return null;
};

type Props = {
	lessonInvite: ILessonInvite;
	response: string;
};

const LessonInvite = ({ response, lessonInvite }: Props) => {
	const [respond, { isLoading, isSuccess, isError, error }] =
		useLessonInviteRespondMutation();

	useEffect(() => {
		respond({
			token: lessonInvite.token,
			response,
		});
	}, []);

	if (isLoading) {
		return <Typography>loading ...</Typography>; //TODO sergei?
	}

	if (isError) {
		return (
			<Typography>
				error ... <pre>{JSON.stringify(error, null, 2)}</pre>
			</Typography>
		); //TODO sergei?
	}

	if (isSuccess) {
		switch (response) {
			case "accept":
				return (
					<LessonInviteAccepted lessonId={lessonInvite.lessonId} />
				);
			case "reject":
				return (
					<Typography>
						Lesson invitation rejected we told your teacher as well
					</Typography>
				);
		}
	}

	return null;
};

const LessonInviteWrapper = () => {
	const { token, response } = useLoaderData() as LoaderRet;
	const {
		data: lessonInvite,
		isLoading,
		isError,
	} = useGetLessonInviteQuery(token);
	const session = useAppSelector(selectSession);
	const location = useLocation();

	if (isLoading) {
		return null; // TODO sergei? some loading indicator maybe?
	}
	if (isError) {
		return (
			<Typography>
				Well this didn't work, link seems to be invalid!
			</Typography> //TODO sergei
		);
	}

	if (lessonInvite && session.status === "loggedout") {
		return (
			<Navigate
				replace={true}
				to={`/login?e=${encode(lessonInvite.email)}&t=${
					lessonInvite.token
				}&ot=${encodeURIComponent(
					location.pathname + location.search
				)}`}
			/>
		);
	} else if (lessonInvite && session.status === "loggedin") {
		return <LessonInvite lessonInvite={lessonInvite} response={response} />;
	} else {
		return <Typography>checking session state ...</Typography>; //TODO sergei?
	}
};

export default LessonInviteWrapper;
