//sample compoennt just printing hello

import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { Stage } from "react-konva";
import { useLoaderData } from "react-router";
import { RawItem } from "routes/app/Class/stageview/components/Item";
import { RawLayer } from "routes/app/Class/stageview/components/Layer";
import {
	LoadingBubble,
	Scrollbar,
} from "routes/app/Class/stageview/constants/styledComponents";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useGetSlideCollectionQuery } from "rtk/api";
import { Slide } from "rtk/types";
import { RawStage as IRawStage } from "store/reducers/class/stages";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Fa, FaButton } from "styled/muiComponents";
import { hi } from "date-fns/locale";
import { theme } from "theme";

type Props = { id: number };

const StyledCarussel = styled(Grid)(({ theme }) => ({
	width: "100%",
	height: "100%",
}));
const StyledButton = styled(Button)(({ theme }) => ({
	position: "absolute",
	height: 48,
	width: 48,
	minWidth: 48,
	maxWidth: 48,
	borderRadius: 24,
	top: "50%",
	padding: 0,
	margin: 0,
	zIndex: 100,
}));

const SlideCollectionView = ({ id }: Props) => {
	const navigate = useNavigate();

	const {
		data: slideCollection,
		isLoading,
		isError,
		isSuccess,
	} = useGetSlideCollectionQuery(id);

	const [activeSlideIdx, setActiveSlideIdx] = useState<number>(0);

	useEffect(() => {
		if (
			!isLoading &&
			isSuccess &&
			slideCollection &&
			slideCollection.slides.length > 0
		) {
			setActiveSlideIdx(0);
		}
	}, [isLoading, isError, isSuccess, slideCollection]);

	const handleNext = () => {
		setActiveSlideIdx((prev) => prev + 1);
	};

	const handlePrev = () => {
		setActiveSlideIdx((prev) => prev - 1);
	};

	const handleSelectPreview = (idx: number) => {
		setActiveSlideIdx(idx);
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	if (isLoading) {
		return <LoadingBubble />;
	}

	if (isError) {
		return <h1> Something went wrong, see logs</h1>;
	}

	if (!slideCollection) return null; // should not happen, just for typescript

	return (
		<Grid
			container
			item
			justifyContent="center"
			alignItems="center"
			direction="column"
			wrap="nowrap"
			style={{ height: "100%" }}
		>
			<Grid item style={{ height: "100%", width: "100%" }}>
				{activeSlideIdx !== undefined ? (
					<Carussel
						onNext={handleNext}
						onPrev={handlePrev}
						slide={slideCollection?.slides[activeSlideIdx]}
						hasNext={
							slideCollection.slides.length - 1 > activeSlideIdx
						}
						hasPrev={activeSlideIdx > 0}
					/>
				) : (
					<LoadingBubble />
				)}
			</Grid>
			<Grid
				item
				style={{
					height: "100px",
					width: "100%",
					maxWidth: "100%",
				}}
			>
				<Preview
					slides={slideCollection.slides}
					activeIdx={activeSlideIdx}
					onClick={handleSelectPreview}
				/>
			</Grid>
		</Grid>
	);
};

const Preview = ({
	slides,
	activeIdx,
	onClick,
}: {
	slides: Slide[];
	activeIdx: number;
	onClick: (idx: number) => void;
}) => {
	const previewRef = useRef<HTMLDivElement>(null);
	const [raw, setRaw] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			if (previewRef.current) {
				const idx = activeIdx;
				const { width, height } =
					previewRef.current.getBoundingClientRect();
				const scaleWidth = width / slides[idx].stage.width;
				const scaleHeight = height / slides[idx].stage.height;

				let scale;
				if (scaleWidth < scaleHeight) {
					scale = scaleWidth;
				} else {
					scale = scaleHeight;
				}
				setRaw({
					height: slides[idx].stage.height * scale,
					width: slides[idx].stage.width * scale,
				});
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [raw.width, raw.height]);

	return (
		<Grid
			container
			direction="row"
			flexWrap="nowrap"
			style={{ height: "100px", backgroundColor: "lightgrey" }}
		>
			<PerfectScrollbar
				style={{
					width: "100%",
					display: "flex",
					flexWrap: "nowrap",
					flexDirection: "row",
					gap: 0,
					padding: 0,
					borderRadius: 0,
				}}
			>
				{slides.map((slide, idx) => (
					<Grid
						key={idx}
						item
						display="flex"
						justifyContent="center"
						alignItems="center"
						onClick={() => onClick(idx)}
						style={{
							backgroundColor:
								idx === activeIdx
									? theme.palette.primary.main
									: "transparent",
							padding: "8px",
							height: "auto",
							aspectRatio: "4/3",
							cursor: "pointer",
						}}
					>
						<div
							style={{
								width: "100%",
								height: "100%",
								boxSizing: "border-box",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							ref={previewRef}
						>
							<RawStage
								stage={slide.stage}
								width={raw.width}
								height={raw.height}
							/>
						</div>
					</Grid>
				))}
			</PerfectScrollbar>
		</Grid>
	);
};

const Carussel = ({
	onNext,
	onPrev,
	slide,
	hasNext,
	hasPrev,
}: {
	onNext: any;
	onPrev: any;
	slide: Slide;
	hasNext: boolean;
	hasPrev: boolean;
}) => {
	const carusselRef = useRef<HTMLDivElement>(null);
	const [raw, setRaw] = useState({ width: 0, height: 0 });

	useEffect(() => {
		console.log(carusselRef.current);
		const handleResize = () => {
			if (carusselRef.current) {
				const { width, height } =
					carusselRef.current.getBoundingClientRect();

				const scaleWidth = width / slide.stage.width;
				const scaleHeight = height / slide.stage.height;

				const scale = Math.min(scaleWidth, scaleHeight);

				console.log({ scaleWidth, scaleHeight, scale, width, height });
				setRaw({
					height: slide.stage.height * scale,
					width: slide.stage.width * scale,
				});
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [raw.width, raw.height]);

	console.log("raw", raw);

	return (
		<StyledCarussel
			item
			container
			justifyContent="center"
			alignItems="center"
			ref={carusselRef}
		>
			<StyledButton
				color="primary"
				variant="contained"
				disabled={!hasPrev}
				onClick={onPrev}
				style={{ left: 16 }}
			>
				<Fa icon={["fas", "arrow-left"]} fixedWidth />
			</StyledButton>
			<RawStage
				stage={slide.stage}
				width={raw.width}
				height={raw.height}
			/>
			<StyledButton
				color="primary"
				variant="contained"
				disabled={!hasNext}
				onClick={onNext}
				style={{ right: 16 }}
			>
				<Fa icon={["fas", "arrow-right"]} fixedWidth />
			</StyledButton>
		</StyledCarussel>
	);
};

export const RawStage = ({
	stage,
	width,
	height,
}: {
	stage: IRawStage;
	width: any;
	height: any;
}) => (
	<Stage width={width} height={height}>
		{stage?.layers.map((layer) => (
			<RawLayer
				key={layer.id}
				layer={layer}
				scale={{
					width: width / stage.width,
					height: height / stage.height,
				}}
			>
				{(items) =>
					items.map((item) => (
						<RawItem
							key={item.id}
							item={item}
							scale={{
								width: width / stage.width,
								height: height / stage.height,
							}}
						/>
					))
				}
			</RawLayer>
		))}
	</Stage>
);

const SlideCollectionViewContainer = () => {
	const id = useLoaderData() as number | null;
	if (id) {
		return <SlideCollectionView id={id} />;
	} else {
		return (
			<h1> Maybe space to create new slide collections from scratch?</h1>
		);
	}
};

export const SlideCollectionViewContainerWithId = ({ id }: { id: number }) => {
	return <SlideCollectionView id={id} />;
};

export default SlideCollectionViewContainer;
