import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

const ToolButton = styled(Button)(({ theme }) => ({
  width: "30px",
  height: "30px",
  padding: "0px !important",
  transition: "all 0.1s ease",
  minWidth: "30px !important",
  borderRadius: "15px",
}));

type Props = ButtonProps & { active?: boolean };

const MyToolButton = forwardRef(
  ({ active, style, ...props }: Props, ref: any) => {
    const theme = useTheme();
    const activeStyle = active
      ? { backgroundColor: theme.palette.white.main, color: "#2b2240" }
      : {};
    const combinedStyle = { ...activeStyle, ...style };
    return (
      <ToolButton ref={ref} {...props} style={combinedStyle} size="small" />
    );
  }
);

export default MyToolButton;
