import React from "react";
import { styled } from "@mui/material/styles";
import MuiDialogContent from "@mui/material/DialogContent";

const StyledDialogContent = styled(MuiDialogContent)(({ theme }) => ({
	padding: `${theme.spacing(3)}px !important`,
	borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
}));

const DialogContent = (props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<StyledDialogContent
			//disableTypography
			{...other}
			style={{ paddingTop: "24px" }}
		>
			{" "}
			{/* paddingTop: "24px" is a fix. If you are reading this, check if the bug has already been fixed: https://github.com/mui/material-ui/issues/27851 */}
			{children}
		</StyledDialogContent>
	);
};

export default DialogContent;
