import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { useValidation } from "components/form/validators";
import { useTranslation } from "react-i18next";
import { useLoginMutation } from "rtk/api";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { Fa, Typography } from "../../styled/muiComponents";
import { styled } from "@mui/material/styles";
import { decode } from "util/hashids";
import { useAppDispatch } from "store/hooks";
import { setNavbarState } from "store/reducers/app/reducer";
import { useEffect } from "react";
import {
	LoginWrapper,
	LoginContainer,
} from "routes/app/Class/stageview/constants/styledComponents";

const FormField = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const Login = () => {
	const { t } = useTranslation();
	const { required } = useValidation(t);
	const [login, { isLoading, isError, error, isSuccess }] =
		useLoginMutation();

	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	dispatch(setNavbarState("closed"));
	// }, []);

	const params = {
		email: searchParams.get("e")
			? decode(searchParams.get("e") as string)
			: null,
		token: searchParams.get("t"),
	};

	interface FormValues {
		field1?: string;
		field2?: string;
	}

	// Define the validation function
	const validateAtLeastOneField = (values: FormValues) => {
		const errors: Partial<FormValues> = {};
		if (!values.field1 && !values.field2) {
			errors.field1 = "At least one of these fields must be filled";
			errors.field2 = "At least one of these fields must be filled";
		}
		return errors;
	};

	const requiredUser = (value: any) => (value ? undefined : "Required");

	const err = error as any;

	return (
		<LoginWrapper
			container
			justifyContent="center"
			alignItems="center"
			direction="column"
			wrap="nowrap"
		>
			<img
				src="/assets/class-guru.svg"
				alt="Classuru"
				style={{ height: "100px", marginBottom: "32px" }}
			></img>
			<Grid
				container
				item
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<LoginContainer
					container
					item
					xs={12}
					sm={8}
					md={4}
					xl={3}
					direction={"column"}
					spacing={2}
				>
					<Grid
						container
						direction="column"
						item
						style={{ padding: 0 }}
					>
						<Grid item>
							<Typography variant="h1" mb={3}>
								Login
							</Typography>
						</Grid>
						<Grid item>
							<Form
								onSubmit={login}
								initialValues={{
									emailOrUsername: params.email,
								}}
							>
								{({ handleSubmit, values }) => (
									<form onSubmit={handleSubmit}>
										<FormField
											name="emailOrUsername"
											color="primary"
											label={t("Email or Username")}
											fieldProps={{ validate: required }}
											disabled={!!params.email}
											required
										/>
										<FormField
											name="password"
											color="primary"
											label={t("Password")}
											type="password"
											fieldProps={{ validate: required }}
											required
										/>
										<Button
											color="primary"
											disabled={isLoading}
											size="large"
											disableElevation
											variant="contained"
											type="submit"
											style={{
												padding: "14px",
												width: "100%",
											}}
										>
											<Fa
												icon={[
													"far",
													"arrow-right-to-arc",
												]}
												fixedWidth
												style={{ marginRight: "8px" }}
											/>
											{t("login")}
										</Button>
									</form>
								)}
							</Form>
						</Grid>
					</Grid>
					<Grid item mt={2} style={{ padding: 0 }}>
						{isError && err && (
							<>
								<Typography color="secondary">
									{err.data.error}
								</Typography>
							</>
						)}
						<Grid
							container
							direction="row"
							justifyContent="space-between"
						>
							<Button
								disableElevation
								variant="text"
								component={Link}
								size="small"
								to="/reset_password"
							>
								{t("Reset password")}
							</Button>

							<Button
								component={Link}
								size="small"
								to={`/register?${searchParams.toString()}`}
							>
								{t("Register")}
							</Button>
						</Grid>
					</Grid>
				</LoginContainer>
			</Grid>
		</LoginWrapper>
	);
};

export const loginRoute = {
	path: "/login",
	element: <Login />,
};

export default Login;
