enum ValidationError {
    Required = "required"
}

export const required = (value: any, t: any) => value ? undefined : t(ValidationError.Required);

const with_translation = (f: any, t: any) => {
    return (v: any) => f(v, t)
}

const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined)

export const useValidation = (t: any) => {
    return {
        required: with_translation(required, t),
        compose: composeValidators
    }
}