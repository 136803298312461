import Konva from "konva";
import { useEffect, useRef } from "react";
import { Circle } from "react-konva";
import { Point } from "types/common";

export type PingProps = Point & { fill: string };

const Ping = ({ x, y, fill }: PingProps) => {
  const ref1 = useRef<Konva.Circle>(null);
  const ref2 = useRef<Konva.Circle>(null);

  useEffect(() => {
    if (ref1.current === null) return;
    const circle = ref1.current;
    circle.to({
      duration: 0.5,
      scaleX: 0.1,
      scaleY: 0.1,
      onFinish: () => {
        circle.to({
          duration: 0.5,
          scaleX: 1,
          scaleY: 1,
          onFinish: () => {
            circle.to({
              duration: 0.5,
              scaleX: 0.1,
              scaleY: 0.1,
              onFinish: () => {
                circle.to({
                  duration: 0.25,
                  scaleX: 0.5,
                  scaleY: 0.5,
                });
              },
            });
          },
        });
      },
    });
  }, [ref1]);

  useEffect(() => {
    if (ref2.current === null) return;
    const circle = ref2.current;
    circle.to({
      duration: 0.5,
      scaleX: 1,
      scaleY: 1,
      onFinish: () => {
        circle.to({
          duration: 0.5,
          scaleX: 0.1,
          scaleY: 0.1,
          onFinish: () => {
            circle.to({
              duration: 0.5,
              scaleX: 1,
              scaleY: 1,
              onFinish: () => {
                circle.to({
                  duration: 0.25,
                  scaleX: 0.5,
                  scaleY: 0.5,
                });
              },
            });
          },
        });
      },
    });
  }, [ref2]);

  return (
    <>
      <Circle x={x} y={y} opacity={0.5} radius={20} fill={fill} ref={ref1} />
      <Circle
        x={x}
        y={y}
        opacity={0.5}
        scaleX={0.1}
        scaleY={0.1}
        radius={20}
        fill={fill}
        ref={ref2}
      />
    </>
  );
};

export default Ping;
