import Webcam from "./webcam";
import { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";
import { useTranslation } from "react-i18next";

const Stream = styled(Grid)(({ theme }) => ({
	aspectRatio: "4 / 6",
	width: "100%",
	//borderBottom: `1px solid ${theme.palette.white.light}`,
	//borderTop: `1px solid ${theme.palette.white.light}`,
	overflow: "hidden",
	paddingRight: "15px",
}));

type Props = {
	studentTool: Tool | null;
	showToolButtons: boolean;
	bigCam: boolean;
};

const CamsComponent = ({ studentTool, showToolButtons, bigCam }: Props) => {
	const [mute, setMute] = useState(false);
	const [camOn, setCamOn] = useState(true);
	const { t } = useTranslation();
	const API = useRef<IJitsiMeetExternalApi | null>(null);

	const toggleMic = () => {
		API.current?.executeCommand("toggleAudio");
		setMute(!mute);
	};

	const toggleCam = () => {
		API.current?.executeCommand("toggleVideo");
		setCamOn(!camOn);
	};

	return (
		<>
			<Stream
				container
				item
				direction="column"
				justifyContent="start"
				alignItems="center"
				style={
					bigCam
						? {
								position: "absolute",
								left: "15px",
								top: "60px",
								width: "calc(100% - 30px)",
								height: "calc(100% - 120px)",
								borderRadius: "16px",
								padding: "0px",
						  }
						: {}
				}
			>
				<Webcam
					ref={API}
					studentTool={studentTool}
					showToolButtons={showToolButtons}
					bigCam={bigCam}
					camOn={camOn}
					mute={mute}
					toggleMic={toggleMic}
					toggleCam={toggleCam}
				/>
			</Stream>
			{/* //! fix this?? */}
			{/* <button onClick={() => setOpen(true)}>devices</button>
			 <DeviceDialog open={open} API={API} handleClose={handleClose} /> */}

			{/* <Stream container item justifyContent="center" alignItems="center">
				<WebcamSelf
					camOn={camOn}
					mute={mute}
					toggleMic={toggleMic}
					toggleCam={toggleCam}
				/>
			</Stream> */}
		</>
	);
};

export default CamsComponent;
