import { Navigate, Outlet, useLocation } from "react-router-dom";

import { selectSession } from "../store/reducers/session";
import { useAppSelector } from "../store/hooks";

const ProtectedRoute = () => {
	const session = useAppSelector(selectSession);
	const location = useLocation();

	if (session.status === "loggedout") {
		return (
			<Navigate
				to={`login?ot=${encodeURIComponent(
					location.pathname + location.search
				)}`}
			/>
		);
	} else {
		return <Outlet />;
	}
};

export default ProtectedRoute;
