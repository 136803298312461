import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Exlorer from "components/explorer/Explorer";
import { PageContainer } from "components/pages/PageContainer";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuProps } from "@mui/material/Menu";
import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { Fa } from "styled/muiComponents";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";

import MuiDialog from "components/dialogs/Dialog";
import MuiDialogTitle from "components/dialogs/DialogTitle.jsx";
import DialogContent from "components/dialogs/DialogContent.jsx";
import DialogActions from "components/dialogs/DialogActions.jsx";
import {
	useCreateSlideCollectionMutation,
	useGetSlideCollectionsQuery,
	useUploadImageMutation,
} from "rtk/api";
import { LoadingBubble } from "../Class/stageview/constants/styledComponents";
import { SlideCollection } from "rtk/types";
import { MaterialDisplay } from "./MaterialDisplay";
import { MaterialDialogDisplay } from "./MaterialDIalogDisplay";
import { useNavigate } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledSidebarGrid = styled(Grid)(({ theme }) => ({
	backgroundColor: "rgba(0,0,0,0.05)",
	minWidth: "300px",
	maxWidth: "400px",
}));
const MediaGridItem = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: `${theme.spacing(1)}`,
	width: "100%",
	height: "100%",
	overflow: "hidden",
	cursor: "pointer",
	"& img": {
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
}));
const DialogImg = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(2),
}));

const MediaGrid = styled("div")(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(auto-fill, minmax(256px, 1fr))",
	gap: theme.spacing(2),
	width: "100%",
}));

const Material = () => {
	const dispatch = useAppDispatch();

	const { data: slideCollections, isLoading } = useGetSlideCollectionsQuery();
	const { t } = useTranslation();
	const [personName, setPersonName] = React.useState<string[]>([]);
	const [editStates, setEditStates] = React.useState(false); // Added state
	const [open, setOpen] = React.useState(false);
	const [currentMaterial, setCurrentMaterial] = React.useState<any | null>(
		null
	);
	const navigate = useNavigate();

	const handleChange = (event: SelectChangeEvent<typeof personName>) => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	useEffect(() => {
		if (isLoading) {
			return;
		}

		if (!slideCollections || slideCollections.length === 0) {
			console.log("goes into empty");
			navigate("/slide_collections/create/pdf");
		}
	}, [isLoading, slideCollections]);

	const handleClickOnSlideCollection = (slideCollection: SlideCollection) => {
		setCurrentMaterial({
			material: slideCollection,
			type: "SLIDECOLLECTION",
		});
		setOpen(!open);
	};

	if (isLoading) {
		return <LoadingBubble />;
	}

	return (
		<PageContainer maxWidth="lg">
			<Grid container direction="column">
				<Grid
					container
					item
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					mb={5}
				>
					<Typography variant="h1">Material</Typography>
					<Button
						color="primary"
						variant="contained"
						component={Link}
						disableElevation
						to="/slide_collections/create/pdf"
						style={{ color: "white" }}
						size="small"
					>
						<Fa
							icon={["far", "upload"]}
							fixedWidth
							style={{ marginRight: "6px" }}
							size="sm"
						/>
						Upload PDF
					</Button>
				</Grid>
				{/* <Grid
					container
					item
					direction="row"
					justifyContent="start"
					mb={5}
					spacing={2}
				>
					<Grid item xs={12} md={4}>
						<TextField
							id="outlined-basic"
							label="Search"
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Fa icon={["fal", "search"]} />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<FormControl sx={{ width: "100%", maxWidth: "100%" }}>
							<InputLabel id="demo-multiple-checkbox-label">
								Date
							</InputLabel>
							<Select
								labelId="date"
								id="date"
								multiple
								value={personName}
								onChange={handleChange}
								input={<OutlinedInput label="Date" />}
								renderValue={(selected) => selected.join(", ")}
							>
								{fileTypes.map((fileTypes) => (
									<MenuItem key={fileTypes} value={fileTypes}>
										<Checkbox
											checked={
												personName.indexOf(fileTypes) >
												-1
											}
										/>
										<ListItemText primary={fileTypes} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<FormControl sx={{ width: "100%", maxWidth: "100%" }}>
							<InputLabel id="demo-multiple-checkbox-label">
								File Types
							</InputLabel>
							<Select
								labelId="demo-multiple-checkbox-label"
								id="demo-multiple-checkbox"
								multiple
								value={personName}
								onChange={handleChange}
								input={<OutlinedInput label="File Types" />}
								renderValue={(selected) => selected.join(", ")}
							>
								{fileTypes.map((fileTypes) => (
									<MenuItem key={fileTypes} value={fileTypes}>
										<Checkbox
											checked={
												personName.indexOf(fileTypes) >
												-1
											}
										/>
										<ListItemText primary={fileTypes} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4}>
						<Autocomplete
							multiple
							id="checkboxes-tags-demo"
							//size="small"
							options={top100Films}
							disableCloseOnSelect
							getOptionLabel={(option) => option.title}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.title}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Tags"
									placeholder="Type to search"
								/>
							)}
						/>
					</Grid>
				</Grid> */}
				<Grid
					container
					item
					direction="row"
					justifyContent="start"
					alignItems="start"
					mb={5}
					ml={-1}
					mr={-1}
					style={{ width: "auto" }}
				>
					<MediaGrid>
						{slideCollections?.map(
							(slideCollection: SlideCollection) => (
								<MaterialDisplay
									material={slideCollection}
									onClick={() =>
										handleClickOnSlideCollection(
											slideCollection
										)
									}
									type={"SLIDECOLLECTION"} //hardcoded until more types
								/>
							)
						)}
					</MediaGrid>
				</Grid>
				{/* <MuiDialog
					open={editStates}
					onClose={() => setEditStates(false)}
					size="lg"
				>
					<MuiDialogTitle>{t("Media")}</MuiDialogTitle>
					<IconButton
						aria-label="close"
						sx={{
							position: "absolute",
							right: 12,
							top: 10,
							width: "40px",
							color: "#ffffff",
						}}
						onClick={() => setEditStates(false)}
					>
						<Fa icon={["far", "xmark"]} />
					</IconButton>
					<Grid container direction="row">
						<DialogImg
							item
							md={8}
							container
							justifyContent="center"
						>
							<img
								src="https://picsum.photos/300/500"
								alt="random"
								height="100%"
							/>
						</DialogImg>
						<StyledSidebarGrid item md={4}></StyledSidebarGrid>
					</Grid>
				</MuiDialog> */}
				<MuiDialog
					open={open && currentMaterial}
					onClose={() => setOpen(false)}
					size="lg"
					paperStyle={{ height: "100%", width: "100%" }}
				>
					<MuiDialogTitle>{t("Media")}</MuiDialogTitle>
					<IconButton
						aria-label="close"
						sx={{
							position: "absolute",
							right: 12,
							top: 10,
							width: "40px",
							color: "#ffffff",
						}}
						onClick={() => setOpen(false)}
					>
						<Fa icon={["far", "xmark"]} />
					</IconButton>
					<MaterialDialog currentMaterial={currentMaterial} />
				</MuiDialog>
			</Grid>
		</PageContainer>
	);
};

// const fileTypes = ["Slide Collections", "Images", "Video", "Audio"];
// const top100Films = [
// 	{ title: "The Shawshank Redemption", year: 1994 },
// 	{ title: "The Godfather", year: 1972 },
// 	{ title: "The Godfather: Part II", year: 1974 },
// 	{ title: "The Dark Knight", year: 2008 },
// 	{ title: "12 Angry Men", year: 1957 },
// 	{ title: "Schindler's List", year: 1993 },
// 	{ title: "Pulp Fiction", year: 1994 },
// 	{
// 		title: "The Lord of the Rings: The Return of the King",
// 		year: 2003,
// 	},
// 	{ title: "The Good, the Bad and the Ugly", year: 1966 },
// 	{ title: "Fight Club", year: 1999 },
// 	{
// 		title: "The Lord of the Rings: The Fellowship of the Ring",
// 		year: 2001,
// 	},
// 	{
// 		title: "Star Wars: Episode V - The Empire Strikes Back",
// 		year: 1980,
// 	},
// 	{ title: "Forrest Gump", year: 1994 },
// 	{ title: "Inception", year: 2010 },
// 	{
// 		title: "The Lord of the Rings: The Two Towers",
// 		year: 2002,
// 	},
// 	{ title: "One Flew Over the Cuckoo's Nest", year: 1975 },
// 	{ title: "Goodfellas", year: 1990 },
// 	{ title: "The Matrix", year: 1999 },
// 	{ title: "Seven Samurai", year: 1954 },
// 	{
// 		title: "Star Wars: Episode IV - A New Hope",
// 		year: 1977,
// 	},
// 	{ title: "City of God", year: 2002 },
// 	{ title: "Se7en", year: 1995 },
// 	{ title: "The Silence of the Lambs", year: 1991 },
// 	{ title: "It's a Wonderful Life", year: 1946 },
// 	{ title: "Life Is Beautiful", year: 1997 },
// 	{ title: "The Usual Suspects", year: 1995 },
// 	{ title: "Léon: The Professional", year: 1994 },
// 	{ title: "Spirited Away", year: 2001 },
// 	{ title: "Saving Private Ryan", year: 1998 },
// 	{ title: "Once Upon a Time in the West", year: 1968 },
// 	{ title: "American History X", year: 1998 },
// 	{ title: "Interstellar", year: 2014 },
// ];
export default Material;

const MaterialDialog = ({ currentMaterial }: any) => {
	return (
		<>
			{/* 
		<Grid
			container
			direction="row"
			wrap="nowrap"
			style={{ height: "100%", maxWidth: "100%" }}
		>
			<Grid item style={{ overflow: "hidden", flexGrow: 1 }}>
			*/}

			<MaterialDialogDisplay
				material={currentMaterial.material}
				type={currentMaterial.type}
			/>

			{/*
			</Grid>
			<StyledSidebarGrid item md={4}></StyledSidebarGrid>
		</Grid>
		*/}
		</>
	);
};
