import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Chat from 'store/reducers/chat/Chat';

const loader = ({ params }: LoaderFunctionArgs): String => String(params.roomId)

const Playground = () => {
    const roomId = useLoaderData() as ReturnType<typeof loader>
    console.log(roomId)
    return (
        <div>
            <h3> Playground </h3>
            <Chat roomId={roomId} />
        </div>
    )
}

export const playgroundRoute =
{
    path: "playground/:roomId",
    loader,
    element: <Playground />
}

export default Playground;