import { JitsiMeeting, JaaSMeeting } from "@jitsi/react-sdk";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
//import { IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { Button, TextField } from "@mui/material";
import { useRef, forwardRef, useEffect, useState } from "react";
import { useJwtTokenQuery } from "rtk/api";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import ToolButton from "../../routes/app/Class/components/ToolButton";
import { Fa } from "../../styled/muiComponents";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";
import { View, selectClass, setStudentTool } from "store/reducers/class/class";
import { useAppDispatch, useAppSelector } from "store/hooks";

const CamContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    position: "relative",
    overflow: "hidden",
    aspectRatio: "4/3",
    "&:hover": {
        div: {
            opacity: 1,
        },
    },
}));
const CamToolsContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    position: "absolute",
    padding: "5px",
    bottom: 0,
    left: 0,
    width: "´100px",
    height: "50px",
    zIndex: 100,
    opacity: 0.25,
    transition: "all 0.5s ease",
}));

type Props = {
    type: any
    teacher: boolean
}

const MediaShare = forwardRef(({ type, teacher }: Props, ref) => {
    const { t } = useTranslation();
    const API = useRef<IJitsiMeetExternalApi>(null);
    const { data, error, isLoading } = useJwtTokenQuery();
    const dispatch = useAppDispatch();
    const { roomId } = useAppSelector(selectClass);
    const [url, setUrl] = useState<null | string>(null)

    const handleSubmit = (event: any) => {
        event.preventDefault()
        setUrl(event.target[0].value)
        API.current?.executeCommand('toggleFilmStrip')
        API.current?.executeCommand(
            "startShareVideo",
            event.target[0].value
        );
    }

    if(API.current){
        console.log('toggle pls')
        API.current.executeCommand('toggleFilmStrip')
    }

    if (isLoading || !data) return <div>loading...</div>;
    if (!roomId) return <div>no room id</div>;

    return (
        <>
            {teacher &&
                <form onSubmit={handleSubmit}>
                    <TextField />
                    <Button type="submit" variant='contained'>Start</Button>
                </form>
            }
            <CamContainer
                container
                direction="column"
                justifyItems="center"
                justifyContent="center"
                id="container"
            >
                <JaaSMeeting
                    appId={"vpaas-magic-cookie-c4914d37f4ae4551942881ba2a88d601"} // TODO ENV
                    // TODO GENERATE
                    jwt={data.token}
                    roomName={`${roomId}-test`}
                    interfaceConfigOverwrite={{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                        DISPLAY_WELCOME_FOOTER: false,
                        MOBILE_APP_PROMO: false,
                        SETTINGS_SECTIONS: [],
                        VIDEO_QUALITY_LABEL_DISABLED: true,
                        SHOW_CHROME_EXTENSION_BANNER: false,
                    }}
                    configOverwrite={{
                        startWithAudioMuted: true,
                        disableModeratorIndicator: true,
                        startWithVideoMuted: true,
                        //startScreenSharing: true,
                        enableEmailInStats: false,
                        prejoinPageEnabled: false,
                        toolbarButtons: [],
                        hideConferenceTimer: true,
                        hideConferenceSubject: true,
                        filmStrip: {
                            disableStageFilmstrip: true,
                            disableResizable: true
                        },
                        // filmStrip: false,


                        //disableResizable: true,
                        //disableStageFilmstrip: true,
                        participantsPane: {
                        hideModeratorSettingsTab: true,
                        hideMoreActionsButton: true,
                        hideMuteAllButton: true,
                        }
                    }}
                    getIFrameRef={(camRef: any) => {
                        camRef.style.aspectRatio = "4/3";
                        camRef.style.width = "100%";
                        camRef.style.height='100%'
                        
                    }}
                    onApiReady={(externalApi: any) => {
                        //@ts-ignore TODO fix whatever it says
                        API.current = externalApi
                        console.log("should toggle")
                        externalApi.executeCommand('toggleFilmStrip')
                    }}
                />
            </CamContainer>
        </>
    );
});

export default MediaShare


