import React from "react";
import { styled } from "@mui/material/styles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Typography } from "../../styled/muiComponents";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	margin: 0,
	padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
	borderBottom: `1px solid ${theme.palette.primary.main}`,
	color: theme.palette.primary.contrastText,
}));
const CloseButton = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(1),
	top: theme.spacing(1),
	color: theme.palette.grey[500],
}));

const DialogTitle = (props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<StyledDialogTitle /*disableTypography*/ {...other}>
			{children}
			{onClose ? (
				<CloseButton aria-label="close" onClick={onClose}>
					<CloseIcon />
				</CloseButton>
			) : null}
		</StyledDialogTitle>
	);
};

export default DialogTitle;
