import React, { useRef, useEffect, useCallback, useState } from "react";
import {
	Calendar,
	momentLocalizer,
	SlotInfo,
	View,
	Views,
} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarStyles.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useGetLessonsQuery } from "rtk/api";
import ViewLessonDialog from "./dialogs/ViewLessonDialog";
import CreateCalendarEntryDialog from "./dialogs/CreateCalendarEntryDialog";
import { Lesson } from "rtk/types";
import { useAppDispatch } from "store/hooks";
import { useSearchParams } from "react-router-dom";

const localizer = momentLocalizer(moment);

function PaperComponent(props: PaperProps) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper
				elevation={0}
				{...props}
				style={{ outline: "10px solid rgba(255,255,255,0.25)" }}
			/>
		</Draggable>
	);
}

const TimeTableWrapper = styled(Grid)(({ theme }) => ({
	//backgroundColor: theme.palette.primary.main,
	display: "flex",
	width: "100%",
	height: "100vh",
	padding: theme.spacing(3),
	".rbc-event": {
		//borderRadius: 0,
	},
	".rbc-event:focus": {
		//outline: 0,
	},
	".rbc-day-slot .rbc-event": {
		//border: `1px solid ${theme.palette.primary.main}`,
	},
	".rbc-event, .rbc-day-slot .rbc-background-event": {
		backgroundColor: "#ffc65c",
		color: theme.palette.primary.main,
	},
	".rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event":
		{
			backgroundColor: "#e6a23c",
			color: theme.palette.primary.main,
		},
	".rbc-time-header.rbc-overflowing": {
		borderRight: "1px solid rgba(255,255,255,0.1)",
	},
	".rbc-day-slot .rbc-events-container": {
		marginRight: "0px",
	},
	".rbc-label": {
		fontSize: "0.8em",
		padding: "0 7px",
	},
}));
const customFormats = {
	timeGutterFormat: (date: Date, culture?: string, localizer?: object) =>
		moment(date).format("HH:mm"),

	eventTimeRangeFormat: (
		{ start, end }: { start: Date; end: Date },
		culture?: string,
		localizer?: object
	) => `${moment(start).format("HH:mm")} - ${moment(end).format("HH:mm")}`,

	dayHeaderFormat: (date: Date, culture?: string, localizer?: object) =>
		moment(date).format("dddd, DD MMMM"),
};

const TimeTable: React.FC = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [viewDialog, setViewDialog] = useState<
		{ open: true; lesson: Lesson } | { open: false }
	>({ open: false });

	const clickRef = useRef<number | null>(null);
	const [currentView, setCurrentView] = useState<View>(
		(searchParams.get("view") as View) || Views.WEEK
	);
	const [currentNavigationDate, setCurrentNavigationDate] =
		useState<moment.Moment>(
			searchParams.get("date")
				? moment(searchParams.get("date"))
				: moment()
		);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [eventTitel, setEventTitel] = useState("");
	const [events, setEvents] = useState<Event[]>([]);
	const [createDialog, setCreateDialogOpen] = useState<{
		open: boolean;
		slotInfo?: SlotInfo;
	}>({ open: false, slotInfo: undefined });
	const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
	const [height, setHeight] = useState<number>(0);
	const containerRef = useRef<any>();

	useEffect(() => {
		const getScrollbarWidth = (): number => {
			const outer = document.createElement("div");
			outer.style.visibility = "hidden";
			outer.style.overflow = "scroll";
			document.body.appendChild(outer);

			const inner = document.createElement("div");
			outer.appendChild(inner);

			const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

			outer.parentNode?.removeChild(outer);

			return scrollbarWidth;
		};

		setScrollbarWidth(getScrollbarWidth());
	}, []);

	useEffect(() => {
		return () => {
			window.clearTimeout(clickRef.current ?? undefined);
		};
	}, []);

	useEffect(() => {
		setSearchParams({
			date: currentNavigationDate.toISOString(),
			view: currentView,
		});
	}, [currentNavigationDate, currentView, setSearchParams]);

	const { data: lessons } = useGetLessonsQuery({
		from: currentNavigationDate
			.startOf(currentView as "week" | "day" | "month")
			.toISOString(),
		to: currentNavigationDate
			.endOf(currentView as "week" | "day" | "month")
			.toISOString(),
	});

	const onSelectEvent = useCallback((calEvent: any) => {
		/*window.clearTimeout(clickRef.current ?? undefined);
    clickRef.current = window.setTimeout(() => {
      const evTitel = calEvent.title;
      setEventTitel(evTitel);
      setDialogOpen(true);
    }, 250);*/
		const lesson = calEvent.resource as Lesson;
		setViewDialog({
			open: true,
			lesson,
		});
	}, []);

	const handleClose = () => {
		setDialogOpen(false);
	};

	const handleViewDialogClose = () => {
		setViewDialog({ open: false });
	};

	const handleSelectSlot = (slotInfo: SlotInfo) => {
		setCreateDialogOpen({ open: true, slotInfo });
	};

	const handleChangeNavigationDate = (date: Date) => {
		setCurrentNavigationDate(moment(date));
	};

	const handleChangeView = (view: View) => {
		setCurrentNavigationDate(moment());
		setCurrentView(view);
	};

	/*useEffect(() => {
    const updateHeight = () => {
      if (!containerRef?.current?.clientHeight) {
        return;
      }
      setHeight(containerRef?.current?.clientHeight);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    // Rückgabe einer Bereinigungsfunktion, um den Event-Listener zu entfernen
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [containerRef]);*/
	if (lessons === undefined) {
		return <h1> Loading ...</h1>;
	}

	return (
		<TimeTableWrapper>
			{/*<TimeZoneTimeline timeZone="Europe/Berlin" />*/}
			<div
				style={{ width: "100%", height: "100%", overflowX: "hidden" }}
				ref={containerRef}
			>
				<Calendar
					localizer={localizer}
					events={lessons.map((lesson) => ({
						title: lesson.name,
						start: new Date(lesson.startAt),
						end: new Date(lesson.endAt),
						resource: lesson,
					}))}
					startAccessor="start"
					endAccessor="end"
					step={30}
					timeslots={2}
					style={
						{
							//height: `${height}px`,
							/*color: "#ffffff" , width: `calc(100% + ${scrollbarWidth}px)`  */
						}
					}
					view={currentView}
					formats={customFormats}
					onDoubleClickEvent={onSelectEvent}
					onSelectEvent={onSelectEvent}
					onSelectSlot={handleSelectSlot}
					onNavigate={handleChangeNavigationDate}
					onView={handleChangeView}
					selectable
					views={["month", "week", "day"]}
					date={currentNavigationDate.toDate()}
				/>

				<ViewLessonDialog
					open={viewDialog.open}
					onClose={handleViewDialogClose}
					lesson={viewDialog.open ? viewDialog.lesson : undefined}
				/>

				<CreateCalendarEntryDialog
					open={createDialog.open}
					slotInfo={createDialog.slotInfo}
					onClose={() =>
						setCreateDialogOpen({
							open: false,
							slotInfo: undefined,
						})
					}
				/>
			</div>
		</TimeTableWrapper>
	);
};

export default TimeTable;
