import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faEraser,
    faXmarksLines,
    faSquareDashed,
    faBullseyePointer,
    faText,
    faPlus,
    faMinus,
    faArrowRight,
    faArrowLeft,
    faXmark,
    faArrowRightToArc,
    faPenToSquare,
    faPresentationScreen,
    faCameraWeb,
    faCameraWebSlash,
    faPaintbrush,
    faPlay,
    faImages,
    faSquareXmark,
    faScreencast,
    faMicrophone,
    faMicrophoneSlash,
    faMagnifyingGlass,
    faCorner,
    faGear,
    faRightToBracket,
    faShuffle,
	faProjector,
	faAdd,
    faUpload,
    faFolderOpen,
    faFolderClosed
} from '@fortawesome/pro-regular-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

library.add(
    faEraser,
    faXmarksLines,
    faSquareDashed,
    faBullseyePointer,
    faText,
    faPlus,
    faMinus,
    faArrowRight,
    faArrowLeft,
    faXmark,
    faArrowRightToArc,
    faPenToSquare,
    faPresentationScreen,
    faCameraWeb,
    faCameraWebSlash,
    faPaintbrush,
    faPlay,
    faImages,
    faSquareXmark,
    faScreencast,
    faMicrophone,
    faMicrophoneSlash,
    faMagnifyingGlass,
    faCorner,
    faGear,
    faRightToBracket,
    faShuffle,
	faProjector,
	faAdd,
    faUpload,
    faFolderOpen,
    faFolderClosed
    )