import { MediaToolBar } from "../constants/styledComponents";
import Tooltip from "@mui/material/Tooltip";
import ToolButton from "../components/ToolButton";
import Zoom from "@mui/material/Zoom";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectClass, View, changeView } from "store/reducers/class/class";
import { Fa } from "styled/muiComponents";
import Grid from "@mui/material/Grid";

export const MediaToolbarButtons = () => {
	const classRoom = useAppSelector(selectClass);
	const dispatch = useAppDispatch();

	const setShowBackground = (foo: any) => {}; // TODO

	return (
		<Grid
			container
			item
			direction="row"
			justifyContent="center"
			alignItems="center"
			wrap="nowrap"
			style={{
				transition: "all 0.2s ease",
				height: "60px",
				paddingTop: "5px",
				paddingBottom: "5px",
				paddingRight: classRoom.view === View.WebCam ? "0" : "20%",
				paddingLeft:
					classRoom.activeStageId === null ||
					classRoom.view === View.ScreenSharing ||
					classRoom.view === View.WebCam
						? 0
						: "60px",
			}}
		>
			<MediaToolBar>
				<Tooltip
					title="Presention"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						active={classRoom.view === View.Presentation}
						variant="contained"
						disableElevation
						onClick={() => dispatch(changeView(View.Presentation))}
					>
						<Fa
							icon={["far", "presentation-screen"]}
							fixedWidth
							size="sm"
						/>
					</ToolButton>
				</Tooltip>
				<Tooltip
					title="Whiteboard"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						active={classRoom.view === View.Whiteboard}
						variant="contained"
						disableElevation
						onClick={() => dispatch(changeView(View.Whiteboard))}
					>
						<Fa icon={["far", "paintbrush"]} fixedWidth size="sm" />
					</ToolButton>
				</Tooltip>
				<Tooltip
					title="Screen Sharing"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						active={classRoom.view === View.ScreenSharing}
						variant="contained"
						disableElevation
						onClick={() => dispatch(changeView(View.ScreenSharing))}
						//disabled
						//style={{ color: "rgba(255,255,255,0.5)" }}
					>
						<Fa icon={["far", "screencast"]} fixedWidth size="sm" />
					</ToolButton>
				</Tooltip>
				<Tooltip
					title="Webcam"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						active={classRoom.view === View.WebCam}
						variant="contained"
						disableElevation
						onClick={() => dispatch(changeView(View.WebCam))}
						//disabled
						//style={{ color: "rgba(255,255,255,0.5)" }}
					>
						<Fa icon={["far", "camera-web"]} fixedWidth size="sm" />
					</ToolButton>
				</Tooltip>
				<Tooltip
					title="Video"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						active={classRoom.view === View.Video}
						variant="contained"
						disableElevation
						onClick={() => dispatch(changeView(View.Video))}
						//disabled
						style={
							{
								//color: "rgba(255,255,255,0.5)",
							}
						}
					>
						<Fa icon={["far", "play"]} fixedWidth size="sm" />
					</ToolButton>
				</Tooltip>
				<Tooltip
					title="Background"
					placement="top"
					TransitionComponent={Zoom}
					arrow
				>
					<ToolButton
						// disabled
						style={{
							color: "rgba(255,255,255,0.5)",
						}}
						active={classRoom.view === View.Background}
						variant="contained"
						disableElevation
						onClick={() => {
							dispatch(changeView(View.Background));
							setShowBackground(true);
						}}
					>
						<Fa icon={["far", "images"]} fixedWidth size="sm" />
					</ToolButton>
				</Tooltip>
			</MediaToolBar>
		</Grid>
	);
};
